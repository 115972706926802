<template>
  <div>
    <!-- 数据展示表格 -->
    <el-table
      :data="tableData"
      v-loading="loading"
      border
      stripe
      style="width: 100%"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column prop="id" label="id"> </el-table-column>
      <el-table-column prop="info" label="信息"> </el-table-column>
      <el-table-column prop="user_id" label="用户ID"> </el-table-column>
      <el-table-column prop="username" label="用户名"> </el-table-column>
      <el-table-column prop="money" label="金额"> </el-table-column>
      <el-table-column prop="moneys" label="真实金额"> </el-table-column>
      <el-table-column prop="addtime" label="申请时间">
        <template slot-scope="scope" v-if="scope.row.addtime">
          {{ formatDate(scope.row.addtime) }}
        </template>
      </el-table-column>
      <el-table-column prop="uptime" label="审核时间">
        <template slot-scope="scope" v-if="scope.row.uptime">
          {{ formatDate(scope.row.uptime) }}
        </template>
      </el-table-column>
      <el-table-column prop="chanid" label="通道">
        <template slot-scope="scope">
          <span v-if="scope.row.chanid === 1" style="color: #1578ff"
            >支付宝</span
          >
          <span v-else-if="scope.row.chanid === 2" style="color: #2aae67"
            >微信支付</span
          >
        </template>
      </el-table-column>
      <el-table-column prop="status" label="状态">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status === 0" type="warning">待支付</el-tag>
          <el-tag v-else-if="scope.row.status === 1" type="success"
            >已支付</el-tag
          >
          <el-tag v-if="scope.row.status === 2" type="danger">支付失败</el-tag>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <el-row :gutter="20" style="margin: 0 20px 0 20px">
      <el-col :span="12" :offset="0" style="line-height: 60px">
        <span>共 {{ total }} 页</span>
        <span style="margin-left: 20px">显示页数</span>
        <el-input-number
          v-model="list"
          controls-position="right"
          @change="GetRechargeLists"
          :min="1"
          :max="10"
          style="margin-left: 10px"
        ></el-input-number>
      </el-col>
      <el-col :span="12" :offset="0">
        <div
          style="
            display: flex;
            justify-content: right;
            align-items: right;
            margin-top: 20px;
          "
        >
          <el-button
            round
            style="padding: 10px"
            @click="changePage(currentPage - 1)"
            :disabled="currentPage === 1"
          >
            <i class="el-icon-arrow-left"></i>
          </el-button>
          <div v-for="page in total" :key="page" style="margin: 0 5px">
            <div
              @click="changePage(page)"
              :class="{ 'active-page': currentPage === page }"
              class="page-button"
            >
              {{ page }}
            </div>
          </div>
          <el-button
            round
            style="padding: 10px"
            @click="changePage(currentPage + 1)"
            :disabled="currentPage === total"
          >
            <i class="el-icon-arrow-right"></i>
          </el-button>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import moment from "moment";
import { GetRechargeList } from "@/api/api";
export default {
  data() {
    return {
      tableData: [],
      loading: false,
      page: 1,
      list: 5,
      total: 0, // 总页数
      currentPage: 1,
    };
  },
  methods: {
    changePage(page) {
      if (page < 1 || page > this.total) return;
      this.currentPage = page;
      this.page = page;
      this.GetRechargeLists();
    },
    // 时间格式化
    formatDate(date) {
      return moment.unix(date).format("YYYY-MM-DD HH:mm:ss");
    },
    GetRechargeLists() {
      this.loading = true;
      const token = localStorage.getItem("token");
      GetRechargeList(token, this.currentPage, this.list).then((res) => {
        if (res.data.code === 200) {
          this.tableData = res.data.data.data;
          this.total = res.data.data.total;
          this.loading = false;
        } else {
          this.$message.error(res.data.msg);
          this.loading = false;
        }
      });
    },
  },
  created() {
    this.GetRechargeLists();
  },
};
</script>

<style>
.page-button {
  background-color: white;
  border: 1px solid #ccc;
  color: black;
  padding: 10px 15px;
  border-radius: 2px;
  cursor: pointer;
}
.page-button.active-page {
  background-color: #409eff;
  color: white;
}
</style>
