<!-- Barcode.vue -->
<template>
  <div style="position: relative; width: 100%">
    <!-- 条形码生成 -->
    <svg ref="barcode" :style="[barcodeStyle, customStyle]"></svg>
  </div>
</template>

<script>
import JsBarcode from "jsbarcode";

export default {
  props: {
    value: {
      type: String,
      required: true,
    },
    options: {
      type: Object,
      default: () => ({
        format: "CODE128",
        lineColor: "#000000",
        width: 2,
        height: 100,
        displayValue: false,
      }),
    },
    customStyle: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    barcodeStyle() {
      return {
        position: "absolute",
        right: "0",
      };
    },
  },
  mounted() {
    this.generateBarcode();
  },
  watch: {
    value() {
      this.generateBarcode();
    },
    options: {
      deep: true,
      handler() {
        this.generateBarcode();
      },
    },
  },
  methods: {
    generateBarcode() {
      JsBarcode(this.$refs.barcode, this.value, this.options);
    },
  },
};
</script>
