import Vue from "vue";
import VueRouter from "vue-router";
import App from "./App.vue";
import axios from "axios";
import router from "./router";
import ElementUI from "element-ui";
import TDesign from "tdesign-vue";
import "element-ui/lib/theme-chalk/index.css";
import "tdesign-vue/es/style/index.css";
import "@fortawesome/fontawesome-free/css/all.css";

Vue.config.productionTip = false;

Vue.use(VueRouter);
Vue.use(TDesign);
Vue.use(ElementUI);

// 配置axios全局使用
Vue.prototype.$http = axios;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
