<template>
  <div>
    <el-button
      @click="addDialogVisible = true"
      type="success"
      style="margin: 0px 0px 10px 10px"
      >添加菜单选项</el-button
    >
    <!-- 数据展示表格 -->
    <el-table
      :data="tableData"
      v-loading="loading"
      border
      stripe
      style="width: 100%"
    >
      <el-table-column prop="id" label="id" width="50"> </el-table-column>
      <el-table-column prop="eid" label="展会id"> </el-table-column>
      <el-table-column prop="label" label="菜单选项"> </el-table-column>
      <el-table-column prop="value" label="菜单值"> </el-table-column>
      <el-table-column prop="is_show" label="是否展示">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.is_show === '1'" type="success"
            >展示中</el-tag
          >
          <el-tag v-else type="danger">未展示</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="money" label="出入场费用"> </el-table-column>
      <el-table-column prop="lengthValue" label="长度超限阈值">
      </el-table-column>
      <el-table-column prop="widthValue" label="宽度超限阈值">
      </el-table-column>
      <el-table-column prop="heightValue" label="高度超限阈值">
      </el-table-column>
      <el-table-column prop="weightValue" label="重量超限阈值">
      </el-table-column>
      <el-table-column prop="excursionLimits" label="超限叠加倍数">
      </el-table-column>
      <el-table-column prop="manageMoney" label="管理费用"> </el-table-column>
      <el-table-column prop="warhouseMoney" label="仓储费用"> </el-table-column>
      <el-table-column prop="createtime" label="创建日期">
        <template slot-scope="scope">{{
          formatDate(scope.row.createtime)
        }}</template>
      </el-table-column>
      <el-table-column prop="updatetime" label="修改日期">
        <template slot-scope="scope">
          <span v-if="scope.row.updatetime">{{
            formatDate(scope.row.updatetime)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <el-button type="primary" @click="openEditDialog(scope.row)"
            >修改</el-button
          >
          <el-popconfirm
            title="确定删除吗？"
            @confirm="delEnames(scope.row.id)"
          >
            <el-button type="danger" slot="reference" style="margin-left: 10px"
              >删除</el-button
            >
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <!-- 修改菜单dialog -->
    <el-dialog title="修改菜单" :visible.sync="dialogVisible" width="70%">
      <el-row :gutter="20">
        <el-col :span="12" :offset="0">
          <el-form>
            <el-form-item label="请输入菜单名称">
              <el-input v-model="updateLabel"></el-input>
            </el-form-item>
            <el-form-item label="请输入出入展馆价格">
              <el-input v-model="updateMoney"></el-input>
            </el-form-item>
            <el-form-item label="请输入长度超限阈值">
              <el-input v-model="updateLengthValue"></el-input>
            </el-form-item>
            <el-form-item label="请输入宽度超限阈值">
              <el-input v-model="updateWidthValue"></el-input>
            </el-form-item>
            <el-form-item label="请输入高度超限阈值">
              <el-input v-model="updateHeightValue"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form>
            <el-form-item label="请输入重量超限阈值">
              <el-input v-model="updateWeightValue"></el-input>
            </el-form-item>
            <el-form-item label="请输入超限叠加倍数">
              <el-input v-model="updateExcursionLimits"></el-input>
            </el-form-item>
            <el-form-item label="请输入管理费用">
              <el-input v-model="updateManageMoney"></el-input>
            </el-form-item>
            <el-form-item label="请输入仓储费用">
              <el-input v-model="updateWarhouseMoney"></el-input>
            </el-form-item>
            <el-form-item label="请选择是否展示">
              <br />
              <el-radio-group v-model="updateIsShow">
                <el-radio :label="0">不展示</el-radio>
                <el-radio :label="1">展示</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editEnames()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 添加菜单dialog -->
    <el-dialog title="添加菜单" :visible.sync="addDialogVisible" width="70%">
      <el-row :gutter="20">
        <el-col :span="12" :offset="0">
          <el-form>
            <el-form-item label="请输入菜单名称">
              <el-input v-model="addLabel"></el-input>
            </el-form-item>
            <el-form-item label="请输入出入展馆价格">
              <el-input v-model="addMoney"></el-input>
            </el-form-item>
            <el-form-item label="请输入长度超限阈值">
              <el-input v-model="addLengthValue"></el-input>
            </el-form-item>
            <el-form-item label="请输入宽度超限阈值">
              <el-input v-model="addWidthValue"></el-input>
            </el-form-item>
            <el-form-item label="请输入高度超限阈值">
              <el-input v-model="addHeightValue"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form>
            <el-form-item label="请输入重量超限阈值">
              <el-input v-model="addWeightValue"></el-input>
            </el-form-item>
            <el-form-item label="请输入超限叠加倍数">
              <el-input v-model="addExcursionLimits"></el-input>
            </el-form-item>
            <el-form-item label="请输入管理费用">
              <el-input v-model="addManageMoney"></el-input>
            </el-form-item>
            <el-form-item label="请输入仓储费用">
              <el-input v-model="addWarhouseMoney"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addEnames()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { addEname, editEname, getEnameList, delEname } from "@/api/api";
import moment from "moment";

export default {
  data() {
    return {
      tableData: [],
      loading: false,
      updateid: "",
      updateLabel: "",
      updateValue: "",
      updateMoney: "",
      updateLengthValue: "",
      updateWidthValue: "",
      updateHeightValue: "",
      updateWeightValue: "",
      updateExcursionLimits: "",
      updateManageMoney: "",
      updateWarhouseMoney: "",
      updateIsShow: "",
      addLabel: "",
      addValue: "",
      addMoney: "",
      addLengthValue: "",
      addWidthValue: "",
      addHeightValue: "",
      addWeightValue: "",
      addExcursionLimits: "",
      addManageMoney: "",
      addWarhouseMoney: "",
      dialogVisible: false,
      addDialogVisible: false,
    };
  },
  methods: {
    // 时间格式化
    formatDate(date) {
      return moment.unix(date).format("YYYY-MM-DD HH:mm:ss");
    },
    openEditDialog(row) {
      this.updateid = row.id;
      this.updateLabel = row.label;
      this.updateValue = row.value;
      this.updateMoney = row.money;
      this.updateLengthValue = row.lengthValue;
      this.updateWidthValue = row.widthValue;
      this.updateHeightValue = row.heightValue;
      this.updateWeightValue = row.weightValue;
      this.updateExcursionLimits = row.excursionLimits;
      this.updateManageMoney = row.manageMoney;
      this.updateWarhouseMoney = row.warhouseMoney;
      this.updateIsShow = row.is_show;
      this.dialogVisible = true;
    },
    getEnameLists() {
      this.loading = true;
      getEnameList().then((res) => {
        if (res.data.code === 200) {
          this.tableData = res.data.data;
          this.loading = false;
        } else {
          this.$message.error(res.data.msg);
          this.loading = false;
        }
      });
    },
    addEnames() {
      this.addValue = this.addLabel;
      addEname(
        this.addLabel,
        this.addValue,
        this.addMoney,
        this.addLengthValue,
        this.addWidthValue,
        this.addHeightValue,
        this.addWeightValue,
        this.addExcursionLimits,
        this.addManageMoney,
        this.addWarhouseMoney
      ).then((res) => {
        if (res.data.code === 200) {
          this.getEnameLists();
          this.$message.success("添加成功！");
          this.addDialogVisible = false;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    editEnames() {
      this.updateValue = this.updateLabel;
      editEname(
        this.updateid,
        this.updateLabel,
        this.updateValue,
        this.updateMoney,
        this.updateLengthValue,
        this.updateWidthValue,
        this.updateHeightValue,
        this.updateWeightValue,
        this.updateExcursionLimits,
        this.updateManageMoney,
        this.updateWarhouseMoney,
        this.updateIsShow
      ).then((res) => {
        if (res.data.code === 200) {
          this.getEnameLists();
          this.dialogVisible = false;
          this.$message.success("修改成功！");
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    delEnames(id) {
      delEname(id).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("删除成功");
          this.getEnameLists();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
  },
  created() {
    this.getEnameLists();
  },
};
</script>
