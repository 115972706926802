<template>
  <div>
    <div style="margin: 0 0 10px 10px">
      <el-switch
        v-model="aiReview"
        active-color="#13ce66"
        inactive-color="#909399"
        active-value="1"
        inactive-value="0"
        @change="switchRealNameAiReviews()"
        v-loading.fullscreen.lock="fullscreenLoading"
      >
      </el-switch>
      <span style="color: #606266"> 是否开启实名认证自动审核 </span>
      <el-popover
        placement="right-start"
        width="200"
        trigger="hover"
        content="开启AI自动审核后系统每小时会自动进行实名审核，通常新的实名认证审核将在2个小时内完成"
      >
        <img
          src="@/assets/ask.png"
          alt=""
          slot="reference"
          style="width: 15px; height: 15px; transform: translateY(2px)"
        />
      </el-popover>
    </div>
    <!-- 数据显示表格 -->
    <el-table
      :data="tableData"
      v-loading="loading"
      border
      stripe
      style="width: 100%"
    >
      <el-table-column type="selection"> </el-table-column>
      <el-table-column prop="id" label="id"> </el-table-column>
      <el-table-column prop="user_id" label="用户id"> </el-table-column>
      <el-table-column prop="idcardno" label="身份证号码"> </el-table-column>
      <el-table-column prop="name" label="姓名"> </el-table-column>
      <el-table-column prop="front_image" label="身份证正面图片URL" width="120">
        <template slot-scope="scope">
          <img
            :src="scope.row.front_image"
            alt=""
            style="width: 100px"
            @click="openImageDialog(scope.row.front_image)"
          />
        </template>
      </el-table-column>
      <el-table-column
        prop="opposite_image"
        label="身份证反面图片URL"
        width="120"
      >
        <template slot-scope="scope">
          <img
            :src="scope.row.opposite_image"
            alt=""
            style="width: 100px"
            @click="openImageDialog(scope.row.opposite_image)"
          />
        </template>
      </el-table-column>
      <el-table-column prop="createtime" label="创建时间">
        <template slot-scope="scope">
          <span v-show="scope.row.createtime">
            {{ formatDate(scope.row.createtime) }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="review_time" label="审核时间">
        <template slot-scope="scope">
          <span v-show="scope.row.review_time">
            {{ formatDate(scope.row.review_time) }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="审核状态">
        <template slot-scope="scope">
          <el-tag v-show="scope.row.status === 1" type="success">通过</el-tag>
          <el-tag v-show="scope.row.status === 2" type="danger">拒绝</el-tag>
          <el-tag v-show="scope.row.status === 0" type="info">待审核</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="fail_reason" label="审核未通过理由">
      </el-table-column>
      <el-table-column prop="review_admin_id" label="审核管理员ID">
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="success" @click="openDialog(scope.row)"
            >审核</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 审核dialog -->
    <el-dialog title="审核用户" :visible.sync="dialogVisible" width="50%">
      <el-row :gutter="20">
        <el-col :span="12" :offset="0">
          <p>用户id：{{ realNameData.user_id }}</p>
          <p>身份证号码：{{ realNameData.idcardno }}</p>
          <p>姓名：{{ realNameData.name }}</p>
          <p>创建时间：{{ formatDate(realNameData.createtime) }}</p>
          <p>审核时间：{{ formatDate(realNameData.review_time) }}</p>
          <p>审核状态：{{ realNameData.status }}</p>
          <p>审核未通过理由：{{ realNameData.fail_reason }}</p>
          <p>审核管理员ID：{{ realNameData.review_admin_id }}</p>
        </el-col>
        <el-col :span="12" :offset="0">
          <p>身份证正面图片：</p>
          <img :src="realNameData.front_image" alt="" style="width: 250px" />
          <p>身份证反面图片：</p>
          <img :src="realNameData.opposite_image" alt="" style="width: 250px" />
        </el-col>
      </el-row>
      <p>审核意见：</p>
      <el-radio-group v-model="status">
        <el-radio :label="1">通过</el-radio>
        <el-radio :label="2">拒绝</el-radio>
      </el-radio-group>
      <div v-show="status === 2">
        <p>拒绝理由：</p>
        <el-input
          type="textarea"
          :rows="3"
          placeholder="请输入拒绝理由"
          v-model="failReason"
        >
        </el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="reviewPersonRealNames()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 查看图片弹窗 -->
    <el-dialog title="查看图片" :visible.sync="imageDialogVisible" width="40%">
      <img :src="url" alt="" style="width: 100%" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="imageDialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
    <!-- 分页器 -->
    <el-row :gutter="20" style="margin: 0 20px 0 20px">
      <el-col :span="12" :offset="0" style="line-height: 60px">
        <span>共 {{ total }} 页</span>
        <span style="margin-left: 20px">显示页数</span>
        <el-input-number
          v-model="list"
          controls-position="right"
          @change="GetRechargeLists"
          :min="1"
          :max="100"
          style="margin-left: 10px"
        ></el-input-number>
      </el-col>
      <el-col :span="12" :offset="0">
        <div
          style="
            display: flex;
            justify-content: right;
            align-items: right;
            margin-top: 20px;
          "
        >
          <el-button
            round
            style="padding: 10px"
            @click="changePage(currentPage - 1)"
            :disabled="currentPage === 1"
          >
            <i class="el-icon-arrow-left"></i>
          </el-button>
          <div v-for="page in total" :key="page" style="margin: 0 5px">
            <div
              @click="changePage(page)"
              :class="{ 'active-page': currentPage === page }"
              class="page-button"
            >
              {{ page }}
            </div>
          </div>
          <el-button
            round
            style="padding: 10px"
            @click="changePage(currentPage + 1)"
            :disabled="currentPage === total"
          >
            <i class="el-icon-arrow-right"></i>
          </el-button>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {
  getRealNameAiReviewSwitch,
  GetRealNameList,
  reviewPersonRealName,
  switchRealNameAiReview,
} from "@/api/api";
import moment from "moment";

export default {
  data() {
    return {
      tableData: [],
      realNameData: {},
      loading: false,
      page: 1,
      list: 5,
      total: 0, // 总页数
      currentPage: 1,
      dialogVisible: false,
      imageDialogVisible: false,
      passid: "",
      status: "",
      failReason: "",
      aiReview: "",
      url: "",
      fullscreenLoading: false,
    };
  },
  methods: {
    openDialog(row) {
      this.realNameData = row;
      this.passid = row.id;
      this.dialogVisible = true;
    },
    openImageDialog(url) {
      this.url = url;
      this.imageDialogVisible = true;
    },
    formatDate(date) {
      return moment.unix(date).format("YYYY-MM-DD HH:mm:ss");
    },
    GetRealNameLists() {
      this.loading = true;
      const token = localStorage.getItem("token");
      GetRealNameList(token).then((res) => {
        if (res.data.code === 200) {
          this.tableData = res.data.data.data;
          this.total = res.data.data.total;
          this.loading = false;
        } else {
          this.$message.error(res.data.msg);
          this.loading = false;
        }
      });
    },
    reviewPersonRealNames() {
      const token = localStorage.getItem("token");
      reviewPersonRealName(
        token,
        this.passid,
        this.status,
        this.failReason
      ).then((res) => {
        if (res.data.code === 200) {
          this.GetRealNameLists();
          this.dialogVisible = false;
          this.$message.success("提交审核成功");
        } else {
          this.$message.error(res.data.msg);
          this.dialogVisible = false;
        }
      });
    },
    getRealNameAiReviewSwitchs() {
      const token = localStorage.getItem("token");
      getRealNameAiReviewSwitch(token).then((res) => {
        if (res.data.code === 200) {
          this.aiReview = res.data.data.switch.toString();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    switchRealNameAiReviews() {
      this.fullscreenLoading = true;
      const token = localStorage.getItem("token");
      switchRealNameAiReview(token, this.aiReview).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("Ai审核开关设置成功");
          this.fullscreenLoading = false;
        } else {
          this.$message.error(res.data.msg);
          this.fullscreenLoading = false;
        }
      });
    },
  },
  created() {
    this.GetRealNameLists();
    this.getRealNameAiReviewSwitchs();
  },
};
</script>
