<template>
  <div>
    <el-button
      @click="openAddDialog()"
      type="success"
      style="margin: 0 0 10px 10px"
      >添加展馆</el-button
    >
    <el-button
      @click="openAddVenuesDialog()"
      type="success"
      style="margin: 0 0 10px 10px"
      >批量添加展馆</el-button
    >
    <!-- 数据展示表格 -->
    <el-table
      :data="tableData"
      v-loading="loading"
      border
      stripe
      style="width: 100%"
    >
      <el-table-column prop="id" label="id"> </el-table-column>
      <el-table-column prop="eid" label="归属展馆id"> </el-table-column>
      <el-table-column prop="label" label="展馆选项"> </el-table-column>
      <el-table-column prop="value" label="展馆传参"> </el-table-column>
      <el-table-column prop="createtime" label="创建时间">
        <template slot-scope="scope"
          ><span v-show="scope.row.createtime">{{
            formatDate(scope.row.createtime)
          }}</span></template
        >
      </el-table-column>
      <el-table-column prop="updatetime" label="更新时间">
        <template slot-scope="scope"
          ><span v-show="scope.row.updatetime">{{
            formatDate(scope.row.updatetime)
          }}</span></template
        >
      </el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <el-button @click="openEditDialog(scope.row)" type="primary"
            >编辑</el-button
          >
          <el-popconfirm
            title="确定删除吗？"
            @confirm="delVenues(scope.row.id)"
          >
            <el-button type="danger" slot="reference" style="margin-left: 10px"
              >删除</el-button
            >
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <el-row :gutter="20" style="margin: 0 20px 0 20px">
      <el-col :span="12" :offset="0" style="line-height: 60px">
        <span>共 {{ total }} 页</span>
        <span style="margin-left: 20px">显示页数</span>
        <el-input-number
          v-model="list"
          controls-position="right"
          @change="getVenues"
          :min="1"
          :max="100"
          style="margin-left: 10px"
        ></el-input-number>
      </el-col>
      <el-col :span="12" :offset="0">
        <div
          style="
            display: flex;
            justify-content: right;
            align-items: right;
            margin-top: 20px;
          "
        >
          <el-button
            round
            style="padding: 10px"
            @click="changePage(currentPage - 1)"
            :disabled="currentPage === 1"
          >
            <i class="el-icon-arrow-left"></i>
          </el-button>
          <div v-for="page in total" :key="page" style="margin: 0 5px">
            <div
              @click="changePage(page)"
              :class="{ 'active-page': currentPage === page }"
              class="page-button"
            >
              {{ page }}
            </div>
          </div>
          <el-button
            round
            style="padding: 10px"
            @click="changePage(currentPage + 1)"
            :disabled="currentPage === total"
          >
            <i class="el-icon-arrow-right"></i>
          </el-button>
        </div>
      </el-col>
    </el-row>
    <!-- 添加dialog -->
    <el-dialog title="添加展馆" :visible.sync="dialogVisible" width="30%">
      <el-form>
        <el-form-item label="展馆名称">
          <el-input v-model="addLable" placeholder="请输入展馆名称"></el-input>
        </el-form-item>
      </el-form>
      <p>请选择归属展会</p>
      <el-radio-group v-model="addEid" v-for="item in enameData" :key="item.id">
        <el-radio :label="item.eid">{{ item.label }}</el-radio>
      </el-radio-group>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addVenues()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 批量添加dialog -->
    <el-dialog
      title="批量添加展馆"
      :visible.sync="AddDialogVisible"
      width="30%"
    >
      <el-form>
        <el-form-item label="展馆名称">
          <el-input
            v-model="addLables"
            placeholder="请输入展馆名称，多个展馆之间使用逗号隔开"
          ></el-input>
        </el-form-item>
      </el-form>
      <p>请选择归属展会</p>
      <el-radio-group
        v-model="addEids"
        v-for="item in enameData"
        :key="item.id"
      >
        <el-radio :label="item.eid">{{ item.label }}</el-radio>
      </el-radio-group>
      <span slot="footer" class="dialog-footer">
        <el-button @click="AddDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addVenueLists()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑dialog -->
    <el-dialog title="编辑展馆" :visible.sync="updateDialogVisible" width="30%">
      <el-form>
        <el-form-item label="展馆名称">
          <el-input
            v-model="updateLable"
            placeholder="请输入展馆名称"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="updateDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateVenues()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { addVenueList } from "@/api/api";
import {
  addVenue,
  delVenue,
  getEnameList,
  getVenue,
  updateVenue,
} from "@/api/api";
import moment from "moment";

export default {
  data() {
    return {
      tableData: [],
      enameData: [],
      loading: false,
      dialogVisible: false,
      updateDialogVisible: false,
      AddDialogVisible: false,
      addEid: "",
      addLable: "",
      addValue: "",
      addMoney: "",
      updateid: "",
      updateLable: "",
      updateValue: "",
      updateMoney: "",
      page: 1,
      list: 10,
      total: 0, // 总页数
      currentPage: 1,
      addEids: "",
      addLables: "",
      addValues: "",
    };
  },
  methods: {
    openAddDialog() {
      this.getEnameLists();
      this.dialogVisible = true;
    },
    openAddVenuesDialog() {
      this.getEnameLists();
      this.AddDialogVisible = true;
    },
    openEditDialog(row) {
      this.updateid = row.id;
      this.updateLable = row.label;
      this.updateValue = row.value;
      this.updateDialogVisible = true;
    },
    // 时间格式化
    formatDate(date) {
      return moment.unix(date).format("YYYY-MM-DD HH:mm:ss");
    },
    changePage(page) {
      if (page < 1 || page > this.total) return;
      this.currentPage = page;
      this.page = page;
      this.getVenues();
    },
    getVenues() {
      this.loading = true;
      const token = localStorage.getItem("token");
      getVenue(token, this.page, this.list).then((res) => {
        if (res.data.code === 200) {
          this.loading = false;
          this.tableData = res.data.data.data;
          this.total = res.data.data.total;
        } else {
          this.loading = false;
          this.$message.error(res.data.msg);
        }
      });
    },
    delVenues(id) {
      delVenue(id).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("删除成功！");
          this.getVenues();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    addVenues() {
      this.addValue = this.addLable;
      addVenue(this.addEid, this.addLable, this.addValue).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("添加成功！");
          this.dialogVisible = false;
          this.getVenues();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    addVenueLists() {
      this.addValues = this.addLables;
      addVenueList(this.addEids, this.addLables, this.addValues).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("添加成功！");
          this.AddDialogVisible = false;
          this.getVenues();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    updateVenues() {
      this.updateValue = this.updateLable;
      updateVenue(this.updateid, this.updateLable, this.updateValue).then(
        (res) => {
          if (res.data.code === 200) {
            this.$message.success("修改成功！");
            this.updateDialogVisible = false;
            this.getVenues();
          } else {
            this.$message.error(res.data.msg);
          }
        }
      );
    },
    getEnameLists() {
      getEnameList().then((res) => {
        if (res.data.code === 200) {
          this.enameData = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
  },
  created() {
    this.getVenues();
  },
};
</script>
