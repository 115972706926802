<template>
  <div>
    <!-- 表格 -->
    <el-table
      :data="tableData"
      v-loading="loading"
      border
      stripe
      style="width: 100%"
    >
      <el-table-column prop="id" label="id"> </el-table-column>
      <el-table-column prop="info" label="信息"> </el-table-column>
      <el-table-column prop="user_id" label="用户id"> </el-table-column>
      <el-table-column prop="money" label="金额"> </el-table-column>
      <el-table-column prop="addtime" label="申请时间">
        <template slot-scope="scope">
          <span>{{ formatDate(scope.row.addtime) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="状态"> </el-table-column>
      <el-table-column prop="chanid" label="支付通道">
        <template slot-scope="scope">
          <span v-if="scope.row.chanid === 1">微信</span>
          <span v-if="scope.row.chanid === 2">支付宝</span>
        </template>
      </el-table-column>
      <el-table-column prop="refund_apply_time" label="退款申请时间">
        <template slot-scope="scope">
          {{ formatDate(scope.row.refund_apply_time) }}
        </template>
      </el-table-column>
      <el-table-column prop="refund_finish_time" label="退款申请时间">
        <template slot-scope="scope">
          {{ formatDate(scope.row.refund_finish_time) }}
        </template>
      </el-table-column>
      <el-table-column prop="refund_review_time" label="退款审批时间">
        <template slot-scope="scope">
          {{ formatDate(scope.row.refund_review_time) }}
        </template>
      </el-table-column>
      <el-table-column prop="refund_reason" label="退款申请原因">
      </el-table-column>
      <el-table-column prop="refund_refuse_reason" label="退款申请原因">
      </el-table-column>
      <el-table-column prop="is_refund" label="退款状态">
        <template slot-scope="scope">
          {{ scope.row.is_refund === 1 ? "已退款" : "未退款" }}
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            type="success"
            @click="opendialog(scope.row.id)"
            v-loading.fullscreen.lock="fullscreenLoading"
            >审核</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <el-row :gutter="20" style="margin: 0 20px 0 20px">
      <el-col :span="12" :offset="0" style="line-height: 60px">
        <span>共 {{ total }} 页</span>
        <span style="margin-left: 20px">显示页数</span>
        <el-input-number
          v-model="list"
          controls-position="right"
          @change="GetRechargeLists"
          :min="1"
          :max="100"
          style="margin-left: 10px"
        ></el-input-number>
      </el-col>
      <el-col :span="12" :offset="0">
        <div
          style="
            display: flex;
            justify-content: right;
            align-items: right;
            margin-top: 20px;
          "
        >
          <el-button
            round
            style="padding: 10px"
            @click="changePage(currentPage - 1)"
            :disabled="currentPage === 1"
          >
            <i class="el-icon-arrow-left"></i>
          </el-button>
          <div v-for="page in total" :key="page" style="margin: 0 5px">
            <div
              @click="changePage(page)"
              :class="{ 'active-page': currentPage === page }"
              class="page-button"
            >
              {{ page }}
            </div>
          </div>
          <el-button
            round
            style="padding: 10px"
            @click="changePage(currentPage + 1)"
            :disabled="currentPage === total"
          >
            <i class="el-icon-arrow-right"></i>
          </el-button>
        </div>
      </el-col>
    </el-row>
    <!-- 订单审核弹窗 -->
    <el-dialog title="订单审核" :visible.sync="dialogVisible" width="50%">
      <el-row :gutter="20">
        <el-col :span="12" :offset="0">
          <p>订单id：{{ orderInfo.id }}</p>
          <p>订单信息：{{ orderInfo.info }}</p>
          <p>订单号：{{ orderInfo.orderNo }}</p>
          <p>用户id：{{ orderInfo.user_id }}</p>
          <p>订单金额：{{ orderInfo.money }}</p>
          <p>订单创建时间：{{ formatDate(orderInfo.addtime) }}</p>
        </el-col>
        <el-col :span="12" :offset="0">
          <p>
            支付通道：
            <span v-if="orderInfo.chanid === 1">微信</span>
            <span v-if="orderInfo.chanid === 2">支付宝</span>
          </p>
          <p>退款申请时间：{{ formatDate(orderInfo.refund_apply_time) }}</p>
          <p>退款原因：{{ orderInfo.refund_reason }}</p>
          <p v-if="orderInfo.refund_review_time">
            退款审核时间：{{ formatDate(orderInfo.refund_review_time) }}
          </p>
          <p>退款拒绝原因：{{ orderInfo.refund_refuse_reason }}</p>
        </el-col>
      </el-row>
      <p style="font-weight: bolder">审核操作</p>
      <el-radio-group v-model="status">
        <el-radio :label="1">同意退款</el-radio>
        <el-radio :label="2">拒绝退款</el-radio>
      </el-radio-group>
      <el-input
        v-if="status === 2"
        type="textarea"
        :autosize="{ minRows: 2, maxRows: 4 }"
        style="margin-top: 10px"
        placeholder="请输入拒绝退款理由"
        v-model="refuseReason"
      >
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="reviewRefundOrders()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getRefundOrderList,
  getRefundOrder,
  reviewRefundOrder,
} from "@/api/api";
import moment from "moment";

export default {
  data() {
    return {
      tableData: [],
      loading: false,
      fullscreenLoading: false,
      page: 1,
      list: 5,
      total: 0, // 总页数
      currentPage: 1,
      dialogVisible: false,
      rowid: "",
      orderInfo: {},
      status: "",
      refuseReason: "",
    };
  },
  methods: {
    opendialog(id) {
      this.fullscreenLoading = true;
      const token = localStorage.getItem("token");
      getRefundOrder(token, id).then((res) => {
        if (res.data.code === 200) {
          this.orderInfo = res.data.data;
          this.dialogVisible = true;
          this.fullscreenLoading = false;
        } else {
          this.$message.error(res.data.msg);
          this.fullscreenLoading = false;
        }
      });
    },
    changePage(page) {
      if (page < 1 || page > this.total) return;
      this.currentPage = page;
      this.page = page;
      this.getRefundOrderLists();
    },
    //格式化Unix时间戳
    formatDate(date) {
      return moment.unix(date).format("YYYY-MM-DD HH:mm:ss");
    },
    getRefundOrderLists() {
      this.loading = true;
      const token = localStorage.getItem("token");
      getRefundOrderList(token, this.page, this.list).then((res) => {
        if (res.data.code === 200) {
          this.tableData = res.data.data.data;
          this.total = res.data.data.total;
          this.loading = false;
        } else {
          this.$message.error(res.data.msg);
          this.loading = false;
        }
      });
    },
    reviewRefundOrders() {
      const token = localStorage.getItem("token");
      reviewRefundOrder(
        token,
        this.orderInfo.id,
        this.status,
        this.refuseReason
      ).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("审核成功");
          this.getRefundOrderLists();
          this.dialogVisible = false;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
  },
  created() {
    this.getRefundOrderLists();
  },
};
</script>
