<template>
  <div>
    <div>
      <el-button
        type="success"
        style="margin: 0 0 10px 10px"
        @click="dialogVisible = true"
        >添加轮播图</el-button
      >
    </div>
    <!-- 数据展示表格 -->
    <el-table
      :data="tableData"
      v-loading="loading"
      border
      stripe
      style="width: 100%"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column prop="id" label="id"> </el-table-column>
      <el-table-column prop="banner_url" label="轮播图地址"> </el-table-column>
      <el-table-column prop="priority" label="优先级"> </el-table-column>
      <el-table-column prop="jump_to" label="跳转地址"> </el-table-column>
      <el-table-column prop="is_show" label="是否展示">
        <template slot-scope="scope">
          <span v-show="scope.row.is_show === 1">是</span>
          <span v-show="scope.row.is_show != 1">否</span>
        </template>
      </el-table-column>
      <el-table-column prop="createtime" label="创建时间">
        <template slot-scope="scope" v-if="scope.row.createtime">
          {{ formatDate(scope.row.createtime) }}
        </template>
      </el-table-column>
      <el-table-column prop="updatetime" label="更新时间">
        <template slot-scope="scope" v-if="scope.row.updatetime">
          {{ formatDate(scope.row.updatetime) }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <el-button type="primary" @click="openEditDialog(scope.row)"
            >编辑</el-button
          >
          <el-popconfirm
            title="删除后无法恢复！确定删除吗？"
            @confirm="DeleteBanners(scope.row.id)"
          >
            <el-button slot="reference" type="danger" style="margin-left: 10px"
              >删除</el-button
            >
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <!-- 添加弹窗 -->
    <el-dialog title="添加轮播图" :visible.sync="dialogVisible" width="30%">
      <el-form label-width="100px">
        <el-form-item label="图片外链">
          <el-input v-model="addBanner"></el-input>
        </el-form-item>
        <el-form-item label="图片优先级">
          <el-input v-model="addPriority"></el-input>
        </el-form-item>
        <el-form-item label="图片跳转地址">
          <el-input v-model="addJumpTo"></el-input>
        </el-form-item>
        <el-form-item label="是否展示">
          <el-radio-group v-model="addIsShow">
            <el-radio :label="1">展示</el-radio>
            <el-radio :label="0">不展示</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="AddBanners()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑弹窗 -->
    <el-dialog title="添加轮播图" :visible.sync="editDialogVisible" width="30%">
      <el-form label-width="100px">
        <el-form-item label="图片外链">
          <el-input v-model="editBanner"></el-input>
        </el-form-item>
        <el-form-item label="图片优先级">
          <el-input v-model="editPriority"></el-input>
        </el-form-item>
        <el-form-item label="图片跳转地址">
          <el-input v-model="editJumpTo"></el-input>
        </el-form-item>
        <el-form-item label="是否展示">
          <el-radio-group v-model="editIsShow">
            <el-radio :label="1">展示</el-radio>
            <el-radio :label="0">不展示</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="EditBanners()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { AddBanner, DeleteBanner, EditBanner, GetBannerList } from "@/api/api";
import moment from "moment";
export default {
  data() {
    return {
      tableData: [],
      loading: false,
      addBanner: "",
      addPriority: "",
      addJumpTo: "",
      addIsShow: "",
      editId: "",
      editBanner: "",
      editPriority: "",
      editJumpTo: "",
      editIsShow: "",
      dialogVisible: false,
      editDialogVisible: false,
    };
  },
  methods: {
    openEditDialog(row) {
      this.editId = row.id;
      this.editBanner = row.banner_url;
      this.editPriority = row.priority;
      this.editJumpTo = row.jump_to;
      this.editIsShow = row.is_show;
      this.editDialogVisible = true;
    },
    // 时间格式化
    formatDate(date) {
      return moment.unix(date).format("YYYY-MM-DD HH:mm:ss");
    },
    GetBannerLists() {
      this.loading = true;
      GetBannerList().then((res) => {
        if (res.data.code === 200) {
          this.tableData = res.data.data;
          this.loading = false;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    AddBanners() {
      const token = localStorage.getItem("token");
      AddBanner(
        token,
        this.addBanner,
        this.addPriority,
        this.addJumpTo,
        this.addIsShow
      ).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("添加成功");
          this.dialogVisible = false;
          this.GetBannerLists();
        } else {
          this.$message.error(res.data.msg);
          this.dialogVisible = false;
        }
      });
    },
    EditBanners() {
      const token = localStorage.getItem("token");
      EditBanner(
        token,
        this.editId,
        this.editBanner,
        this.editPriority,
        this.editJumpTo,
        this.editIsShow
      ).then((res) => {
        if (res.data.code === 200) {
          this.GetBannerLists();
          this.editDialogVisible = false;
          this.$message.success("修改成功");
        } else {
          this.$message.error(res.data.msg);
          this.editDialogVisible = false;
        }
      });
    },
    DeleteBanners(id) {
      const token = localStorage.getItem("token");
      DeleteBanner(token, id).then((res) => {
        if (res.data.code === 200) {
          this.GetBannerLists();
          this.$message.success("删除成功");
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
  },
  created() {
    this.GetBannerLists();
  },
};
</script>
