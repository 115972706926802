<template>
  <div>
    <el-button
      type="success"
      style="margin: 0px 0px 10px 10px"
      @click="dialogVisible = true"
      >添加车辆类型</el-button
    >
    <!-- 数据展示表格 -->
    <el-table
      :data="tableData"
      v-loading="loading"
      border
      stripe
      style="width: 100%"
    >
      <el-table-column type="selection"> </el-table-column>
      <el-table-column prop="id" label="id"> </el-table-column>
      <el-table-column prop="type" label="姓名"> </el-table-column>
      <el-table-column prop="createtime" label="创建时间">
        <template slot-scope="scope">
          <span v-if="scope.row.createtime">{{
            formatDate(scope.row.createtime)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="updatetime" label="修改时间">
        <template slot-scope="scope">
          <span v-if="scope.row.updatetime">{{
            formatDate(scope.row.updatetime)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="primary" @click="openEditDialog(scope.row)"
            >修改</el-button
          >
          <el-popconfirm
            title="这是一段内容确定删除吗？"
            @confirm="delVehType(scope.row.id)"
          >
            <el-button slot="reference" type="danger" style="margin-left: 10px"
              >删除</el-button
            >
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页器 -->
    <el-row :gutter="20" style="margin: 0 20px 0 20px">
      <el-col :span="12" :offset="0" style="line-height: 60px">
        <span>共 {{ total }} 页</span>
        <span style="margin-left: 20px">显示页数</span>
        <el-input-number
          v-model="list"
          controls-position="right"
          @change="GetRechargeLists"
          :min="1"
          :max="100"
          style="margin-left: 10px"
        ></el-input-number>
      </el-col>
      <el-col :span="12" :offset="0">
        <div
          style="
            display: flex;
            justify-content: right;
            align-items: right;
            margin-top: 20px;
          "
        >
          <el-button
            round
            style="padding: 10px"
            @click="changePage(currentPage - 1)"
            :disabled="currentPage === 1"
          >
            <i class="el-icon-arrow-left"></i>
          </el-button>
          <div v-for="page in total" :key="page" style="margin: 0 5px">
            <div
              @click="changePage(page)"
              :class="{ 'active-page': currentPage === page }"
              class="page-button"
            >
              {{ page }}
            </div>
          </div>
          <el-button
            round
            style="padding: 10px"
            @click="changePage(currentPage + 1)"
            :disabled="currentPage === total"
          >
            <i class="el-icon-arrow-right"></i>
          </el-button>
        </div>
      </el-col>
    </el-row>
    <!-- 添加弹窗 -->
    <el-dialog
      title="添加车辆分类信息"
      :visible.sync="dialogVisible"
      width="30%"
    >
      <el-form>
        <el-form-item label="车辆分类">
          <el-input v-model="addTypes"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addVehType()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改弹窗 -->
    <el-dialog
      title="修改车辆分类信息"
      :visible.sync="editDialogVisible"
      width="30%"
    >
      <el-form>
        <el-form-item label="车辆分类">
          <el-input v-model="editTypes"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editVehType()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { addVehTypes, delVehTypes, editVehTypes, getVehTypes } from "@/api/api";
import moment from "moment";
export default {
  data() {
    return {
      tableData: [],
      loading: false,
      dialogVisible: false,
      editDialogVisible: false,
      page: 1,
      list: 5,
      total: 0, // 总页数
      currentPage: 1,
      addTypes: "",
      editId: "",
      editTypes: "",
    };
  },
  methods: {
    openEditDialog(row) {
      this.editId = row.id;
      this.editTypes = row.type;
      this.editDialogVisible = true;
    },
    changePage(page) {
      if (page < 1 || page > this.total) return;
      this.currentPage = page;
      this.page = page;
      this.getVehType();
    },
    //格式化Unix时间戳
    formatDate(date) {
      return moment.unix(date).format("YYYY-MM-DD HH:mm:ss");
    },
    getVehType() {
      this.loading = true;
      const token = localStorage.getItem("token");
      getVehTypes(token, this.page, this.list).then((res) => {
        if (res.data.code === 200) {
          this.tableData = res.data.data.data;
          this.total = res.data.data.total;
          this.loading = false;
        } else {
          this.$message.error(res.data.msg);
          this.loading = false;
        }
      });
    },
    addVehType() {
      const token = localStorage.getItem("token");
      addVehTypes(token, this.addTypes).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("添加成功");
          this.getVehType();
          this.dialogVisible = false;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    editVehType() {
      const token = localStorage.getItem("token");
      editVehTypes(token, this.editId, this.editTypes).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("修改成功");
          this.getVehType();
          this.editDialogVisible = false;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    delVehType(id) {
      const token = localStorage.getItem("token");
      delVehTypes(token, id).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("删除成功");
          this.getVehType();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
  },
  created() {
    this.getVehType();
  },
};
</script>
