<template>
  <div>
    <el-button
      type="success"
      style="margin: 0px 0px 10px 10px"
      @click="dialogVisible = true"
      >添加特殊服务信息</el-button
    >
    <!-- 数据展示表格 -->
    <el-table
      :data="tableData"
      v-loading="loading"
      border
      stripe
      style="width: 100%"
    >
      <el-table-column type="selection"> </el-table-column>
      <el-table-column prop="id" label="id"> </el-table-column>
      <el-table-column prop="mac_size" label="机力规格"> </el-table-column>
      <el-table-column prop="count" label="数量"> </el-table-column>
      <el-table-column prop="use_date" label="使用日期"> </el-table-column>
      <el-table-column prop="use_time" label="使用时间"> </el-table-column>
      <el-table-column prop="assembly_time" label="拼装耗时"> </el-table-column>
      <el-table-column prop="createtime" label="创建时间">
        <template slot-scope="scope">
          <span v-if="scope.row.createtime">{{
            formatDate(scope.row.createtime)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="updatetime" label="修改时间">
        <template slot-scope="scope">
          <span v-if="scope.row.updatetime">{{
            formatDate(scope.row.updatetime)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <el-button type="primary" @click="openEditDialog(scope.row)"
            >修改</el-button
          >
          <el-popconfirm
            title="这是一段内容确定删除吗？"
            @confirm="delExhibitMacInfos(scope.row.id)"
          >
            <el-button slot="reference" type="danger" style="margin-left: 10px"
              >删除</el-button
            >
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <!-- 添加dialog -->
    <el-dialog
      title="添加展品信息弹窗"
      :visible.sync="dialogVisible"
      width="60%"
    >
      <el-row :gutter="20">
        <el-col :span="12" :offset="0">
          <el-form>
            <el-form-item label="机力规格">
              <el-input v-model="addMacSize"></el-input>
            </el-form-item>
            <el-form-item label="数量">
              <el-input v-model="addCount"></el-input>
            </el-form-item>
            <el-form-item label="使用日期">
              <br />
              <el-date-picker
                v-model="addUseDate"
                type="date"
                placeholder="选择日期时间"
                align="right"
                :picker-options="pickerOptions"
                style="width: 100%"
              >
              </el-date-picker>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form>
            <el-form-item label="使用时间">
              <el-input v-model="addUseTime"></el-input>
            </el-form-item>
            <el-form-item label="拼装耗时">
              <el-input v-model="addAssemblyTime"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addExhibitMacInfos()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 修改dialog -->
    <el-dialog
      title="修改展品信息弹窗"
      :visible.sync="editDialogVisible"
      width="60%"
    >
      <el-row :gutter="20">
        <el-col :span="12" :offset="0">
          <el-form>
            <el-form-item label="机力规格">
              <el-input v-model="editMacSize"></el-input>
            </el-form-item>
            <el-form-item label="数量">
              <el-input v-model="editCount"></el-input>
            </el-form-item>
            <el-form-item label="使用日期">
              <br />
              <el-date-picker
                v-model="editUseDate"
                type="date"
                placeholder="选择日期时间"
                align="right"
                :picker-options="pickerOptions"
                style="width: 100%"
              >
              </el-date-picker>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form>
            <el-form-item label="使用时间">
              <el-input v-model="editUseTime"></el-input>
            </el-form-item>
            <el-form-item label="拼装耗时">
              <el-input v-model="editAssemblyTime"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>

      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editExhibitMacInfos()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getExhibitMacInfo,
  addExhibitMacInfo,
  delExhibitMacInfo,
  editExhibitMacInfo,
} from "@/api/api";
import moment from "moment";

export default {
  data() {
    return {
      tableData: [],
      Loading: false,
      dialogVisible: false,
      addMacSize: "",
      addCount: "",
      addUseDate: "",
      addUseTime: "",
      addAssemblyTime: "",
      editDialogVisible: false,
      editId: "",
      editMacSize: "",
      editCount: "",
      editUseDate: "",
      editUseTime: "",
      editAssemblyTime: "",
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
    };
  },
  methods: {
    openEditDialog(row) {
      this.editDialogVisible = true;
      this.editId = row.id;
      this.editMacSize = row.mac_size;
      this.editCount = row.count;
      this.editUseDate = row.use_date;
      this.editUseTime = row.use_time;
      this.editAssemblyTime = row.assembly_time;
    },
    //格式化Unix时间戳
    formatDate(date) {
      return moment.unix(date).format("YYYY-MM-DD HH:mm:ss");
    },
    getExhibitMacInfos() {
      this.loading = true;
      const token = localStorage.getItem("token");
      getExhibitMacInfo(token).then((res) => {
        if (res.data.code === 200) {
          this.tableData = res.data.data;
          this.loading = false;
        } else {
          this.loading = false;
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      });
    },
    addExhibitMacInfos() {
      const token = localStorage.getItem("token");
      addExhibitMacInfo(
        token,
        this.addMacSize,
        this.addCount,
        this.addUseDate,
        this.addUseTime,
        this.addAssemblyTime
      ).then((res) => {
        if (res.data.code === 200) {
          this.$message({
            message: "添加成功！",
            type: "success",
          });
          this.dialogVisible = false;
          this.getExhibitMacInfos();
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      });
    },
    delExhibitMacInfos(id) {
      const token = localStorage.getItem("token");
      delExhibitMacInfo(token, id).then((res) => {
        if (res.data.code === 200) {
          this.$message({
            message: "删除成功！",
            type: "success",
          });
          this.getExhibitMacInfos();
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      });
    },
    editExhibitMacInfos() {
      const token = localStorage.getItem("token");
      editExhibitMacInfo(
        token,
        this.editId,
        this.editMacSize,
        this.editCount,
        this.editUseDate,
        this.editUseTime,
        this.editAssemblyTime
      ).then((res) => {
        if (res.data.code === 200) {
          this.$message({
            message: "编辑成功",
            type: "success",
          });
          this.editDialogVisible = false;
          this.getExhibitMacInfos();
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      });
    },
    handleAdd() {
      this.dialogVisible = true;
    },
  },
  created() {
    this.getExhibitMacInfos();
  },
  watch: {
    addUseDate(newVal) {
      const date = new Date(newVal);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      this.addUseDate = `${year}-${month}-${day}`;
    },
  },
};
</script>
