<template>
  <div>
    <el-row>
      <el-col :span="4" :offset="0">
        <el-menu default-active="2" class="el-menu-vertical-demo">
          <img src="@/assets/logo.png" alt="" style="width: 100%" />
          <el-menu-item index="1" @click="open(1)">
            <i class="el-icon-menu"></i>
            <span slot="title">DashBoard</span>
          </el-menu-item>
          <el-submenu index="2">
            <template slot="title">
              <i class="fa fa-user" style="padding: 5px; margin-right: 5px"></i>
              <span>会员管理</span>
            </template>
            <el-menu-item-group>
              <template slot="title">会员管理二级菜单</template>
              <el-menu-item index="2-1" @click="open(21)"
                >会员列表管理</el-menu-item
              >
              <el-menu-item index="2-2" @click="open(22)"
                >会员等级管理</el-menu-item
              >
              <el-menu-item index="2-3" @click="open(23)"
                >会员折扣管理
              </el-menu-item>
              <el-menu-item index="2-4" @click="open(24)"
                >会员手机号实名认证
              </el-menu-item>
              <el-menu-item index="2-5" @click="open(25)"
                >会员订单管理
              </el-menu-item>
              <el-menu-item index="2-6" @click="open(26)"
                >会员发票管理
              </el-menu-item>
              <el-menu-item index="2-7" @click="open(27)"
                >会员发票抬头管理
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="3">
            <template slot="title">
              <i class="fa fa-coins" style="padding: 5px; margin-right: 5px" />
              <span>财务管理 </span>
            </template>
            <el-menu-item-group>
              <template slot="title">财务管理二级菜单</template>
              <el-menu-item index="3-1" @click="open(31)"
                >会员消费记录明细
              </el-menu-item>
              <el-menu-item index="3-2" @click="open(32)"
                >订单财务明细
              </el-menu-item>
              <el-menu-item index="3-3" @click="open(33)"
                >充值订单管理
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="4">
            <template slot="title">
              <i
                class="fa fa-shopping-bag"
                style="padding: 5px; margin-right: 5px"
              />
              <span>商城管理 </span>
            </template>
            <el-menu-item-group>
              <template slot="title">商城管理二级菜单</template>
              <el-menu-item index="4-1">订单管理 </el-menu-item>
              <el-menu-item index="4-2">物流信息管理 </el-menu-item>
              <el-menu-item index="4-3">商城设置 </el-menu-item>
              <el-menu-item index="4-4">交易设置 </el-menu-item>
              <el-menu-item index="4-5">支付方式 </el-menu-item>
              <el-menu-item index="4-6">微信公众平台通知设置 </el-menu-item>
              <el-menu-item index="4-6">短信通知设置 </el-menu-item>
              <el-menu-item index="4-6">管理员权限管理 </el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="5">
            <template slot="title">
              <i
                class="fa fa-shopping-basket"
                style="padding: 5px; margin-right: 5px"
              />
              <span>商品管理</span>
            </template>
            <el-menu-item-group>
              <template slot="title">商品管理二级菜单</template>
              <el-menu-item index="5-1">展会分类设置</el-menu-item>
              <el-menu-item index="5-2">商品管理</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="6">
            <template slot="title">
              <i
                class="fa fa-reorder"
                style="padding: 5px; margin-right: 5px"
              ></i>
              <span>订单管理</span>
            </template>
            <el-menu-item-group>
              <template slot="title">订单管理二级菜单</template>
              <el-menu-item index="6-1" @click="open(61)"
                >&lt;现场服务&gt;订单管理</el-menu-item
              >
              <el-menu-item index="6-2" @click="open(62)"
                >&lt;仓库代收&gt;订单管理</el-menu-item
              >
              <el-menu-item index="6-3">&lt;车证办理&gt;订单管理</el-menu-item>
              <el-menu-item index="6-4">&lt;展品回运&gt;订单管理</el-menu-item>
              <el-menu-item index="6-5" @click="open(65)"
                >&lt;订单退款&gt;订单管理</el-menu-item
              >
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="7">
            <template slot="title">
              <i
                class="fa fa-users"
                style="padding: 5px; margin-right: 5px"
              ></i>
              <span>人员管理</span>
            </template>
            <el-menu-item-group>
              <template slot="title">人员管理二级菜单</template>
              <el-menu-item index="7-1">指挥工</el-menu-item>
              <el-menu-item index="7-2">叉车司机</el-menu-item>
              <el-menu-item index="7-3" @click="open(73)"
                >员工管理</el-menu-item
              >
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="8">
            <template slot="title">
              <i
                class="fa fa-envelope"
                style="padding: 5px; margin-right: 5px"
              ></i>
              <span>邮件管理</span>
            </template>
            <el-menu-item-group>
              <template slot="title">邮件管理二级菜单</template>
              <el-menu-item index="8-1" @click="open(81)"
                >SMTP配置</el-menu-item
              >
              <el-menu-item index="8-2" @click="open(82)"
                >邮件群发</el-menu-item
              >
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="9">
            <template slot="title">
              <i
                class="fa fa-laptop"
                style="padding: 5px; margin-right: 5px"
              ></i>
              <span>站点管理</span>
            </template>
            <el-menu-item-group>
              <template slot="title">站点管理二级菜单</template>
              <el-menu-item index="8-1" @click="open(91)"
                >站点新闻管理</el-menu-item
              >
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="10">
            <template slot="title">
              <!-- <i
                class="fa fa-image"
                style="padding: 5px; margin-right: 5px"
              ></i> -->
              <img
                src="@/assets/wechat-fill.png"
                alt=""
                style="width: 20px; padding: 5px"
              />
              <span>微信小程序管理</span>
            </template>
            <el-menu-item-group>
              <template slot="title">微信小程序管理二级菜单</template>
              <el-menu-item index="8-1" @click="open(101)"
                >首页Banner管理</el-menu-item
              >
              <el-menu-item index="8-2" @click="open(102)"
                >用户实名认证管理</el-menu-item
              >
              <el-menu-item index="8-3" @click="open(103)"
                >小推车订单管理</el-menu-item
              >
              <el-menu-item index="8-4" @click="open(104)"
                >现场服务展会管理</el-menu-item
              >
              <el-menu-item index="8-5" @click="open(105)"
                >现场服务展馆管理</el-menu-item
              >
              <el-menu-item index="8-6" @click="open(106)"
                >机力租赁管理</el-menu-item
              >
              <el-menu-item index="8-7" @click="open(107)"
                >车辆类型管理</el-menu-item
              >
              <el-menu-item index="8-8" @click="open(108)"
                >货物具体信息</el-menu-item
              >
              <el-menu-item index="8-9" @click="open(109)"
                >特殊服务信息</el-menu-item
              >
            </el-menu-item-group>
          </el-submenu>
          <el-menu-item index="110" @click="open(110)">
            <i
              class="fa fa-sliders"
              style="padding: 5px; margin-right: 5px"
            ></i>
            <span slot="title">全局配置管理</span>
          </el-menu-item>
          <el-menu-item index="120" @click="open(120)">
            <i class="fa fa-cog" style="padding: 5px; margin-right: 5px"></i>
            <span slot="title">价格计算配置</span>
          </el-menu-item>
        </el-menu>
      </el-col>
      <el-col :span="20" :offset="0">
        <div style="text-align: right">
          <el-popover placement="top-start" trigger="hover">
            <div>
              <span style="font-size: 16px"
                >当前登录用户:<em
                  ><b>{{ username }} </b></em
                ></span
              >
              <br />
              <el-button
                type="danger"
                style="width: 100%; margin-top: 10px"
                @click="dialogVisible = true"
                >修改密码</el-button
              >
              <br />
              <el-button
                type="success"
                style="width: 100%; margin-top: 10px"
                @click="LogOuts()"
                >退出登录</el-button
              >
            </div>
            <el-image
              :src="avatar"
              style="width: 70px; height: 70px; border-radius: 50px"
              slot="reference"
            ></el-image>
          </el-popover>
        </div>
        <el-tabs
          v-if="isShow == true"
          v-model="editableTabsValue"
          type="card"
          editable
          @edit="handleTabsEdit"
        >
          <el-tab-pane
            :key="item.name"
            v-for="item in editableTabs"
            :label="item.title"
            :name="item.name"
          >
            <component :is="item.component"></component>
          </el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>
    <!-- 修改密码弹窗 -->
    <el-dialog title="修改密码" :visible.sync="dialogVisible" width="30%">
      <el-form>
        <el-form-item label="请输入旧密码"
          ><el-input v-model="oldPassword"></el-input
        ></el-form-item>
        <el-form-item label="请输入新密码"
          ><el-input v-model="newPassword"></el-input
        ></el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="changePasswords()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import DashBoard from "@/components/DashBoard.vue";
import VipList from "@/components/VipList.vue";
import VipLevel from "@/components/VipLevel.vue";
import VipDiscount from "@/components/VipDiscount.vue";
import VipBill from "@/components/VipBill.vue";
import VipOrder from "@/components/VipOrder.vue";
import VipPhone from "@/components/VipPhone.vue";
import SMTPConfig from "./SMTPConfig.vue";
import MailSend from "./MailSend.vue";
import NewsList from "./NewsList.vue";
import MemberPay from "./MemberPay.vue";
import OrderList from "./OrderList.vue";
import RechargeOrder from "./RechargeOrder.vue";
import MiniBanner from "./MiniBanner.vue";
import GlobalConfig from "./GlobalConfig.vue";
import PriceClacConfig from "./PriceClacConfig.vue";
import RealName from "./RealName.vue";

import { LogOut, changePassword } from "@/api/api";
import CartOrder from "./CartOrder.vue";
import EmployeeComponent from "./EmployeeComponent.vue";
import InvoiceHeader from "./InvoiceHeader.vue";
import ReFound from "./ReFound.vue";
import FieldService from "./FieldService.vue";
import ExhibitionHall from "./ExhibitionHall.vue";
import RentType from "./RentType.vue";
import ExhibitOrder from "./ExhibitOrder.vue";
import VehType from "./VehType.vue";
import ExhibitInfo from "./ExhibitInfo.vue";
import ExhibitMacInfo from "./ExhibitMacInfo.vue";
import WarHouseOrder from "./WarHouseOrder.vue";

export default {
  data() {
    return {
      username: "",
      avatar: "",
      oldPassword: "",
      newPassword: "",
      editableTabs: [], // 初始化为空数组
      editableTabsValue: "1",
      isShow: true,
      dialogVisible: false,
      componentsList: {
        1: "DashBoard",
        21: "VipList",
        22: "VipLevel",
        23: "VipDiscount",
        24: "VipPhone",
        25: "VipOrder",
        26: "VipBill",
        27: "InvoiceHeader",
        31: "MemberPay",
        32: "OrderList",
        33: "RechargeOrder",
        61: "ExhibitOrder",
        62: "WarHouseOrder",
        65: "ReFound",
        73: "EmployeeComponent",
        81: "SMTPConfig",
        82: "MailSend",
        91: "NewsList",
        101: "MiniBanner",
        102: "RealName",
        103: "CartOrder",
        104: "FieldService",
        105: "ExhibitionHall",
        106: "RentType",
        107: "VehType",
        108: "ExhibitInfo",
        109: "ExhibitMacInfo",
        110: "GlobalConfig",
        120: "PriceClacConfig",
      },
      currentView: "DashBoard",
    };
  },
  methods: {
    changePasswords() {
      const token = localStorage.getItem("token");
      changePassword(token, this.oldPassword, this.newPassword).then((res) => {
        if (res.data.code === 1) {
          this.$message.success("密码修改成功，返回登录页面");
          this.LogOuts();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    LogOuts() {
      const token = localStorage.getItem("token");
      LogOut(token).then((res) => {
        if (res.data.code === 1) {
          localStorage.removeItem("token");
          localStorage.removeItem("username");
          localStorage.removeItem("avatar");
          localStorage.removeItem("isLoggedIn");
          this.$message.success("退出登录成功");
          this.$router.push("/login");
        } else {
          this.$message.error(res.data.msg);
          localStorage.removeItem("token");
          localStorage.removeItem("username");
          localStorage.removeItem("avatar");
          localStorage.removeItem("isLoggedIn");
          this.$message.success("退出登录成功");
          this.$router.push("/login");
        }
      });
    },
    getInfo() {
      this.username = localStorage.getItem("username");
      this.avatar = localStorage.getItem("avatar");
    },
    open(code) {
      let existingTab = this.editableTabs.find(
        (tab) => tab.name === this.componentsList[code]
      );
      if (!existingTab) {
        this.currentView = this.componentsList[code];
        let menuName = {
          1: "控制台",
          21: "会员列表",
          22: "会员等级",
          23: "会员折扣",
          24: "会员手机实名认证",
          25: "会员订单",
          26: "会员发票",
          27: "会员发票抬头",
          31: "会员消费记录",
          32: "订单财务",
          33: "充值订单",
          61: "现场服务单",
          62: "仓库代收订单",
          65: "订单退款",
          73: "员工管理",
          81: "SMTP配置",
          82: "邮件发送",
          91: "站点新闻管理",
          101: "小程序Banner管理",
          102: "小程序实名认证管理",
          103: "小程序小推车订单管理",
          104: "现场服务展会管理",
          105: "现场服务展馆管理",
          106: "机力租赁分类管理",
          107: "车辆类型管理",
          108: "货物具体信息",
          109: "特殊服务信息",
          110: "全局配置管理",
          120: "价格计算配置",
        };
        this.editableTabs.push({
          title: menuName[code],
          name: this.componentsList[code],
          component: this.componentsList[code],
        });
        this.editableTabsValue = this.componentsList[code];
      } else {
        this.editableTabsValue = existingTab.name;
      }
    },
    handleTabsEdit(targetName, action) {
      if (action === "add") {
        let newTabName = ++this.editableTabs.length;
        this.editableTabs.push({
          title: "New Tab", // 这里可以根据需要设置新的标签页标题
          name: newTabName,
          component: "NewTabComponent", // 你需要替换为实际的组件名称
        });
        this.editableTabsValue = newTabName;
      }
      if (action === "remove") {
        let tabs = this.editableTabs;
        let activeName = this.editableTabsValue;
        if (activeName === targetName) {
          tabs.forEach((tab, index) => {
            if (tab.name === targetName) {
              let nextTab = tabs[index + 1] || tabs[index - 1];
              if (nextTab) {
                activeName = nextTab.name;
              }
            }
          });
        }
        this.editableTabsValue = activeName;
        this.editableTabs = tabs.filter((tab) => tab.name !== targetName);
      }
    },
  },
  created() {
    this.open(1);
    this.getInfo();
  },
  components: {
    DashBoard,
    VipList,
    VipDiscount,
    VipBill,
    VipLevel,
    VipOrder,
    VipPhone,
    SMTPConfig,
    MailSend,
    NewsList,
    MemberPay,
    OrderList,
    RechargeOrder,
    MiniBanner,
    GlobalConfig,
    PriceClacConfig,
    RealName,
    CartOrder,
    EmployeeComponent,
    InvoiceHeader,
    ReFound,
    FieldService,
    ExhibitionHall,
    RentType,
    ExhibitOrder,
    VehType,
    ExhibitInfo,
    ExhibitMacInfo,
    WarHouseOrder,
  },
};
</script>

<style></style>
