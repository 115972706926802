<template>
  <div>
    <el-button
      type="success"
      style="margin: 0px 10px 10px 10px"
      @click="addDialogVisible = true"
      >添加机力租赁分类</el-button
    >
    <!-- 数据展示表格 -->
    <el-table
      :data="tableData"
      v-loading="loading"
      border
      stripe
      style="width: 100%"
    >
      <el-table-column type="selection"> </el-table-column>
      <el-table-column prop="id" label="id"> </el-table-column>
      <el-table-column prop="eid" label="eid"> </el-table-column>
      <el-table-column prop="type" label="分类名称"> </el-table-column>
      <el-table-column prop="createtime" label="创建日期">
        <template slot-scope="scope">
          <span v-if="scope.row.createtime">{{
            formatDate(scope.row.createtime)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="updatetime" label="更新日期">
        <template slot-scope="scope">
          <span v-if="scope.row.updatetime">{{
            formatDate(scope.row.updatetime)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="310">
        <template slot-scope="scope">
          <el-button type="warning" @click="openCheckInfo(scope.row.eid)"
            >查看租赁商品</el-button
          >
          <el-button type="primary" @click="openEditDialog(scope.row)"
            >编辑</el-button
          >
          <el-popconfirm
            title="这是一段内容确定删除吗？"
            @confirm="delRentTypes(scope.row)"
          >
            <el-button slot="reference" type="danger" style="margin-left: 10px"
              >删除</el-button
            >
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <!-- 表格dialog -->
    <el-dialog
      title="查看归属分类租赁商品"
      :visible.sync="rentDialogVisible"
      width="70%"
    >
      <el-button
        type="success"
        style="margin: 0px 0px 10px 0px"
        @click="addProductVisible = true"
        >添加租赁商品</el-button
      >
      <el-table
        :data="productData"
        border
        stripe
        v-loading="rentLoading"
        style="width: 100%"
      >
        <el-table-column prop="id" label="id"> </el-table-column>
        <el-table-column prop="eid" label="归属分类id"> </el-table-column>
        <el-table-column prop="pid" label="租赁商品id"> </el-table-column>
        <el-table-column prop="image" label="商品图片">
          <template slot-scope="scope">
            <img
              :src="scope.row.image"
              alt=""
              style="width: 50px; height: 50px"
            />
          </template>
        </el-table-column>
        <el-table-column prop="product" label="商品名称"> </el-table-column>
        <el-table-column prop="load" label="额定载重"> </el-table-column>
        <el-table-column prop="memo" label="备注"> </el-table-column>
        <el-table-column prop="price" label="价格"> </el-table-column>
        <el-table-column label="操作" width="180">
          <template slot-scope="scope">
            <el-button type="primary" @click="openEditProductDialog(scope.row)"
              >编辑</el-button
            >
            <el-popconfirm
              title="这是一段内容确定删除吗？"
              @confirm="delRentProducts(scope.row.pid)"
            >
              <el-button
                slot="reference"
                type="danger"
                style="margin-left: 10px"
                >删除</el-button
              >
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="rentDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="rentDialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 编辑弹窗 -->
    <el-dialog
      title="编辑机力租赁"
      :visible.sync="editDialogVisible"
      width="30%"
    >
      <el-form>
        <el-form-item label="请输入机力租赁分类">
          <el-input v-model="editType"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editRentTypes()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 增加弹窗 -->
    <el-dialog
      title="新增机力租赁"
      :visible.sync="addDialogVisible"
      width="30%"
    >
      <el-form>
        <el-form-item label="请输入机力租赁分类">
          <el-input v-model="addType"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addRentTypes()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 添加商品弹窗 -->
    <el-dialog
      title="添加租赁商品"
      :visible.sync="addProductVisible"
      width="30%"
    >
      <el-form>
        <el-form-item label="请输入图片外链">
          <el-input v-model="addImage"></el-input>
        </el-form-item>
        <el-form-item label="请输入商品名称">
          <el-input v-model="addProduct"></el-input>
        </el-form-item>
        <el-form-item label="请输入额定荷载">
          <el-input v-model="addLoad"></el-input>
        </el-form-item>
        <el-form-item label="请输入价格(可输入文字 如：面议)">
          <el-input v-model="addPrice"></el-input>
        </el-form-item>
        <el-form-item label="请输入备注 若无留空">
          <el-input v-model="addMemo"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addProductVisible = false">取 消</el-button>
        <el-button type="primary" @click="addRentProducts()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑商品弹窗 -->
    <el-dialog
      title="编辑租赁商品"
      :visible.sync="editProductVisible"
      width="30%"
    >
      <el-form>
        <el-form-item label="请输入图片外链">
          <el-input v-model="editImage"></el-input>
        </el-form-item>
        <el-form-item label="请输入商品名称">
          <el-input v-model="editProduct"></el-input>
        </el-form-item>
        <el-form-item label="请输入额定荷载">
          <el-input v-model="editLoad"></el-input>
        </el-form-item>
        <el-form-item label="请输入价格(可输入文字 如：面议)">
          <el-input v-model="editPrice"></el-input>
        </el-form-item>
        <el-form-item label="请输入备注 若无留空">
          <el-input v-model="editMemo"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editProductVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateRentProducts()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getRentType,
  getRentProducts,
  editRentType,
  delRentType,
  addRentType,
  delRentProduct,
  addRentProduct,
  updateRentProduct,
} from "@/api/api";
import moment from "moment";

export default {
  data() {
    return {
      tableData: [],
      loading: false,
      rentLoading: false,
      productData: [],
      dialogVisible: false,
      rentDialogVisible: false,
      editDialogVisible: false,
      addDialogVisible: false,
      addProductVisible: false,
      editProductVisible: false,
      editId: "",
      editType: "",
      addType: "",
      addEid: "",
      addImage: "",
      addProduct: "",
      addLoad: "",
      addPrice: "",
      addMemo: "",
      editProductId: "",
      editImage: "",
      editProduct: "",
      editLoad: "",
      editPrice: "",
      editMemo: "",
    };
  },
  methods: {
    openEditProductDialog(row) {
      this.editProductId = row.id;
      this.editImage = row.image;
      this.editProduct = row.product;
      this.editLoad = row.load;
      this.editPrice = row.price;
      this.editMemo = row.memo;
      this.editProductVisible = true;
    },
    openCheckInfo(eid) {
      this.addEid = eid;
      this.dialogVisible = true;
      this.getRentProduct(eid);
    },
    openEditDialog(row) {
      this.editId = row.id;
      this.editType = row.type;
      this.editDialogVisible = true;
    },
    //格式化Unix时间戳
    formatDate(date) {
      return moment.unix(date).format("YYYY-MM-DD HH:mm:ss");
    },
    getRentTypes() {
      this.loading = true;
      const token = localStorage.getItem("token");
      getRentType(token).then((res) => {
        if (res.data.code === 200) {
          this.tableData = res.data.data;
          this.loading = false;
        } else {
          this.$message.error(res.data.msg);
          this.loading = false;
        }
      });
    },
    getRentProduct() {
      this.rentLoading = true;
      const token = localStorage.getItem("token");
      getRentProducts(token, this.addEid).then((res) => {
        if (res.data.code === 200) {
          this.rentDialogVisible = true;
          this.productData = res.data.data;
          this.rentLoading = false;
        } else {
          this.rentLoading = false;
          this.$message.error(res.data.msg);
        }
      });
    },
    editRentTypes() {
      const token = localStorage.getItem("token");
      editRentType(token, this.editId, this.editType).then((res) => {
        if (res.data.code === 200) {
          this.getRentTypes();
          this.$message.success("修改成功");
          this.editDialogVisible = false;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    delRentTypes(row) {
      const token = localStorage.getItem("token");
      const id = row.id;
      const eid = row.eid;
      delRentType(token, id, eid).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("删除成功");
          this.getRentTypes();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    addRentTypes() {
      const token = localStorage.getItem("token");
      addRentType(token, this.addType).then((res) => {
        if (res.data.code === 200) {
          this.getRentTypes();
          this.$message.success("添加成功");
          this.addDialogVisible = false;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    delRentProducts(pid) {
      const token = localStorage.getItem("token");
      delRentProduct(token, pid).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("删除成功");
          this.getRentProduct();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    addRentProducts() {
      const token = localStorage.getItem("token");
      addRentProduct(
        token,
        this.addEid,
        this.addImage,
        this.addProduct,
        this.addLoad,
        this.addPrice,
        this.addMemo
      ).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("添加成功");
          this.addProductVisible = false;
          this.getRentProduct();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    updateRentProducts() {
      const token = localStorage.getItem("token");
      updateRentProduct(
        token,
        this.editProductId,
        this.editImage,
        this.editProduct,
        this.editLoad,
        this.editPrice,
        this.editMemo
      ).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("修改成功");
          this.editProductVisible = false;
          this.getRentProduct();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
  },
  created() {
    this.getRentTypes();
  },
};
</script>
