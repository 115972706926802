<template>
  <div>
    <div>
      <el-button
        type="success"
        style="margin: 0 0 10px 10px"
        @click="dialogVisible = true"
        >添加新闻</el-button
      >
    </div>
    <!-- 表格数据展示 -->
    <el-table
      v-loading="loading"
      :data="tableData"
      border
      stripe
      style="width: 100%"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column prop="id" label="id"> </el-table-column>
      <el-table-column prop="type" label="跳转类型">
        <template slot-scope="scope">
          <span>{{ scope.row.type == 1 ? "内部跳转" : "外部跳转" }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="title" label="标题"> </el-table-column>
      <el-table-column prop="content" label="内容">
        <template slot-scope="scope">
          <div class="ellipsis">{{ scope.row.content }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="createtime" label="创建时间">
        <template slot-scope="scope">
          {{ formatDate(scope.row.createtime) }}
        </template>
      </el-table-column>
      <el-table-column prop="updatetime" label="修改时间">
        <template slot-scope="scope">
          {{ formatDate(scope.row.updatetime) }}
        </template>
      </el-table-column>
      <el-table-column prop="url" label="跳转链接"> </el-table-column>
      <el-table-column label="操作" width="180px">
        <template slot-scope="scope">
          <el-button type="primary" @click="OpenEditDialog(scope.row)"
            >编辑</el-button
          >
          <el-popconfirm
            title="一但删除无法恢复！确定删除吗？"
            @confirm="DelNews(scope.row.id)"
          >
            <el-button type="danger" slot="reference" style="margin-left: 10px"
              >删除</el-button
            >
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <el-row :gutter="20" style="margin: 0 20px 0 20px">
      <el-col :span="12" :offset="0" style="line-height: 60px">
        <span>共 {{ total }} 页</span>
      </el-col>
      <el-col :span="12" :offset="0">
        <div
          style="
            display: flex;
            justify-content: right;
            align-items: right;
            margin-top: 20px;
          "
        >
          <el-button
            round
            style="padding: 10px"
            @click="changePage(currentPage - 1)"
            :disabled="currentPage === 1"
          >
            <i class="el-icon-arrow-left"></i>
          </el-button>
          <div v-for="page in total" :key="page" style="margin: 0 5px">
            <div
              @click="changePage(page)"
              :class="{ 'active-page': currentPage === page }"
              class="page-button"
            >
              {{ page }}
            </div>
          </div>
          <el-button
            round
            style="padding: 10px"
            @click="changePage(currentPage + 1)"
            :disabled="currentPage === total"
          >
            <i class="el-icon-arrow-right"></i>
          </el-button>
        </div>
      </el-col>
    </el-row>
    <!-- 添加弹窗 -->
    <el-dialog title="添加新闻" :visible.sync="dialogVisible" width="30%">
      <el-form label-width="80px">
        <el-form-item label="选择跳转类型" label-width="100px">
          <el-radio-group v-model="addType">
            <el-radio :label="1">内部跳转</el-radio>
            <el-radio :label="2">外部跳转</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="标题">
          <el-input v-model="addTitle"></el-input>
        </el-form-item>
        <el-form-item label="内容">
          <el-input v-model="addContent"></el-input>
        </el-form-item>
        <el-form-item label="跳转链接" v-show="addType === 2">
          <el-input v-model="addUrl"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="AddNewss()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改弹窗 -->
    <el-dialog title="修改新闻" :visible.sync="editDialogVisible" width="30%">
      <el-form label-width="80px">
        <el-form-item label="选择跳转类型" label-width="100px">
          <el-radio-group v-model="editType">
            <el-radio :label="1">内部跳转</el-radio>
            <el-radio :label="2">外部跳转</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="标题">
          <el-input v-model="editTitle"></el-input>
        </el-form-item>
        <el-form-item label="内容">
          <el-input v-model="editContent"></el-input>
        </el-form-item>
        <el-form-item label="跳转链接" v-show="editType === 2">
          <el-input v-model="editUrl"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="EditNews()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { AddNews, updateNews } from "@/api/api";
import { DeleteNews, GetNewsList } from "@/api/api";
import moment from "moment";

export default {
  data() {
    return {
      tableData: [],
      loading: false,
      addType: "",
      addTitle: "",
      addContent: "",
      addUrl: "",
      dialogVisible: false,
      editDialogVisible: false,
      editId: "",
      editType: "",
      editTitle: "",
      editContent: "",
      editUrl: "",
      page: 1,
      size: 5,
      total: 0, // 总页数
      currentPage: 1,
    };
  },
  methods: {
    formatDate(date) {
      return moment.unix(date).format("YYYY-MM-DD HH:mm:ss");
    },
    changePage(page) {
      if (page < 1 || page > this.total) return;
      this.currentPage = page;
      this.page = page;
      this.GetNewsLists();
    },
    GetNewsLists() {
      this.loading = true;
      GetNewsList(this.currentPage, this.pageSize).then((res) => {
        if (res.data.code === 200) {
          this.tableData = res.data.data.data;
          this.total = res.data.data.total;
          this.loading = false;
        } else {
          this.$message.error(res.data.msg);
          this.loading = false;
        }
      });
    },
    DelNews(id) {
      const token = localStorage.getItem("token");
      DeleteNews(token, id).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("删除成功");
          this.GetNewsLists();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    AddNewss() {
      const token = localStorage.getItem("token");
      AddNews(
        token,
        this.addType,
        this.addTitle,
        this.addContent,
        this.addUrl
      ).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("添加成功");
          this.GetNewsLists();
          this.dialogVisible = false;
        } else {
          this.dialogVisible = false;
          this.$message.error(res.data.msg);
        }
      });
    },
    OpenEditDialog(row) {
      this.editId = row.id;
      this.editType = row.type;
      this.editTitle = row.title;
      this.editContent = row.content;
      this.editUrl = row.url;
      this.editDialogVisible = true;
    },
    EditNews() {
      const token = localStorage.getItem("token");
      updateNews(
        token,
        this.editId,
        this.editType,
        this.editTitle,
        this.editContent,
        this.editUrl
      ).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("修改成功");
          this.editDialogVisible = false;
          this.GetNewsLists();
        } else {
          this.$message.error(res.data.msg);
          this.GetNewsLists();
        }
      });
    },
    handleCurrentChange(page) {
      this.currentPage = page;
      this.GetNewsLists();
    },
  },
  created() {
    this.GetNewsLists();
  },
};
</script>

<style>
.ellipsis {
  width: 100%; /* 定义容器宽度 */
  white-space: nowrap; /* 确保文本不换行 */
  overflow: hidden; /* 超出容器部分隐藏 */
  text-overflow: ellipsis; /* 超出部分显示省略号 */
}

.page-button {
  background-color: white;
  border: 1px solid #ccc;
  color: black;
  padding: 10px 15px;
  border-radius: 2px;
  cursor: pointer;
}
.page-button.active-page {
  background-color: #409eff;
  color: white;
}
</style>
