<template>
  <div>
    <el-button
      type="success"
      style="margin: 0px 0px 10px 10px"
      @click="dialogVisible = true"
      >添加展品信息</el-button
    >
    <el-button
      type="warning"
      style="margin-left: 10px"
      @click="sendDialogVisible = true"
      >推送催付款信息</el-button
    >
    <!-- 数据展示表格 -->
    <el-table
      :data="tableData"
      v-loading="loading"
      border
      stripe
      style="width: 100%"
    >
      <el-table-column type="selection"> </el-table-column>
      <el-table-column prop="id" label="id"> </el-table-column>
      <el-table-column prop="exhibit_name" label="展品名称"> </el-table-column>
      <el-table-column prop="length" label="长(cm)"> </el-table-column>
      <el-table-column prop="width" label="宽(cm)"> </el-table-column>
      <el-table-column prop="height" label="高(cm)"> </el-table-column>
      <el-table-column prop="cbm" label="体积(cbm)"> </el-table-column>
      <el-table-column prop="weight" label="重量(weight)"> </el-table-column>
      <el-table-column prop="memo" label="备注"> </el-table-column>
      <el-table-column prop="createtime" label="创建时间">
        <template slot-scope="scope">
          <span v-if="scope.row.createtime">{{
            formatDate(scope.row.createtime)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="updatetime" label="修改时间">
        <template slot-scope="scope">
          <span v-if="scope.row.updatetime">{{
            formatDate(scope.row.updatetime)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <el-button type="primary" @click="openEditDialog(scope.row)"
            >修改</el-button
          >
          <el-popconfirm
            title="这是一段内容确定删除吗？"
            @confirm="delExhibitInfos(scope.row.id)"
          >
            <el-button slot="reference" type="danger" style="margin-left: 10px"
              >删除</el-button
            >
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <!-- 添加dialog -->
    <el-dialog
      title="添加展品信息弹窗"
      :visible.sync="dialogVisible"
      width="60%"
    >
      <el-row :gutter="20">
        <el-col :span="12" :offset="0">
          <el-form>
            <el-form-item label="展品名称">
              <el-input v-model="addExhibitName"></el-input>
            </el-form-item>
            <el-form-item label="长(cm)">
              <el-input v-model="addLength"></el-input>
            </el-form-item>
            <el-form-item label="宽(cm)">
              <el-input v-model="addWidth"></el-input>
            </el-form-item>
            <el-form-item label="高(cm)">
              <el-input v-model="addHeight"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form>
            <el-form-item label="体积(Cbm)">
              <el-input v-model="addCbm"></el-input>
            </el-form-item>
            <el-form-item label="重量(kg)">
              <el-input v-model="addWeight"></el-input>
            </el-form-item>
            <el-form-item label="备注">
              <el-input v-model="addMemo"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addExhibitInfos()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改dialog -->
    <el-dialog
      title="修改展品信息弹窗"
      :visible.sync="editDialogVisible"
      width="60%"
    >
      <el-row :gutter="20">
        <el-col :span="12" :offset="0">
          <el-form>
            <el-form-item label="展品名称">
              <el-input v-model="editExhibitName"></el-input>
            </el-form-item>
            <el-form-item label="长(cm)">
              <el-input v-model="editLength"></el-input>
            </el-form-item>
            <el-form-item label="宽(cm)">
              <el-input v-model="editWidth"></el-input>
            </el-form-item>
            <el-form-item label="高(cm)">
              <el-input v-model="editHeight"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form>
            <el-form-item label="体积(Cbm)">
              <el-input v-model="editCbm"></el-input>
            </el-form-item>
            <el-form-item label="重量(kg)">
              <el-input v-model="editWeight"></el-input>
            </el-form-item>
            <el-form-item label="备注">
              <el-input v-model="editMemo"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>

      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editExhibitInfos()">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="推送催付款信息"
      :visible.sync="sendDialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <el-form>
        <el-form-item label="请输入用户手机号">
          <el-input v-model="userphone">
            <el-button
              style="padding-right: 10px"
              slot="suffix"
              type="text"
              @click="getUserIds()"
              >搜索并确认</el-button
            >
          </el-input>
        </el-form-item>
        <el-form-item label="请输入用户订单号">
          <el-input v-model="searchOrderno">
            <el-button
              style="padding-right: 10px"
              slot="suffix"
              type="text"
              @click="getOrderInfos()"
              >搜索并确认</el-button
            >
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="sendDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="sendSubscribeMessages()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getExhibitInfo,
  addExhibitInfo,
  delExhibitInfo,
  editExhibitInfo,
  getUserId,
  getOrderInfo,
  sendSubscribeMessage,
} from "@/api/api";
import moment from "moment";

export default {
  data() {
    return {
      tableData: [],
      Loading: false,
      dialogVisible: false,
      addExhibitName: "",
      addLength: "",
      addWidth: "",
      addHeight: "",
      addCbm: "",
      addWeight: "",
      addMemo: "",
      editDialogVisible: false,
      editExhibitName: "",
      editId: "",
      editLength: "",
      editWidth: "",
      editHeight: "",
      editCbm: "",
      editWeight: "",
      editMemo: "",
      sendDialogVisible: false,
      userphone: "",
      userid: "",
      orderno: "",
      searchOrderno: "",
    };
  },
  methods: {
    openEditDialog(row) {
      this.editDialogVisible = true;
      this.editExhibitName = row.exhibit_name;
      this.editId = row.id;
      this.editLength = row.length;
      this.editWidth = row.width;
      this.editHeight = row.height;
      this.editWeight = row.weight;
      this.editMemo = row.memo;
      this.editCbm = row.cbm;
    },
    //格式化Unix时间戳
    formatDate(date) {
      return moment.unix(date).format("YYYY-MM-DD HH:mm:ss");
    },
    getExhibitInfos() {
      this.loading = true;
      const token = localStorage.getItem("token");
      getExhibitInfo(token).then((res) => {
        if (res.data.code === 200) {
          this.tableData = res.data.data;
          this.loading = false;
        } else {
          this.loading = false;
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      });
    },
    addExhibitInfos() {
      const token = localStorage.getItem("token");
      addExhibitInfo(
        token,
        this.addExhibitName,
        this.addLength,
        this.addWidth,
        this.addHeight,
        this.addCbm,
        this.addWeight,
        this.addMemo
      ).then((res) => {
        if (res.data.code === 200) {
          this.$message({
            message: "添加成功！",
            type: "success",
          });
          this.dialogVisible = false;
          this.getExhibitInfos();
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      });
    },
    delExhibitInfos(id) {
      const token = localStorage.getItem("token");
      delExhibitInfo(token, id).then((res) => {
        if (res.data.code === 200) {
          this.$message({
            message: "删除成功！",
            type: "success",
          });
          this.getExhibitInfos();
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      });
    },
    editExhibitInfos() {
      const token = localStorage.getItem("token");
      editExhibitInfo(
        token,
        this.editId,
        this.editExhibitName,
        this.editLength,
        this.editWidth,
        this.editHeight,
        this.editCbm,
        this.editWeight,
        this.editMemo
      ).then((res) => {
        if (res.data.code === 200) {
          this.$message({
            message: "编辑成功",
            type: "success",
          });
          this.editDialogVisible = false;
          this.getExhibitInfos();
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      });
    },
    getUserIds() {
      const token = localStorage.getItem("token");
      getUserId(token, this.userphone).then((res) => {
        if (res.data.code === 200) {
          this.userid = res.data.data;
          this.$message.success("搜索成功");
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      });
    },
    getOrderInfos() {
      const token = localStorage.getItem("token");
      getOrderInfo(token, this.searchOrderno).then((res) => {
        if (res.data.code === 200) {
          this.orderno = res.data.data;
          this.$message.success("搜索成功");
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      });
    },
    handleAdd() {
      this.dialogVisible = true;
    },
    sendSubscribeMessages() {
      if (!this.userid || this.orderno) {
        // const token = localStorage.getItem("token");
        sendSubscribeMessage(this.userid, this.orderno).then((res) => {
          if (res.data.code === 200) {
            this.$message.success("发送成功");
            this.sendDialogVisible = false;
          } else {
            this.$message.error("发送失败");
          }
        });
      } else {
        this.$message.error("请先选择订单与用户");
      }
    },
  },
  created() {
    this.getExhibitInfos();
  },
};
</script>
