<template>
  <div>
    <el-row :gutter="30" style="padding: 10px 40px 0 40px">
      <el-col :span="12" :offset="0">
        <el-form>
          <el-form-item label="SMTP服务器">
            <el-input v-model="smtpHost" @input="validateSmtpHost"></el-input>
            <span v-if="smtpHostError">{{ smtpHostError }}</span>
          </el-form-item>
          <el-form-item label="用户名">
            <el-input v-model="smtpUser" @input="validateSmtpUser"></el-input>
            <span v-if="smtpUserError">{{ smtpUserError }}</span>
          </el-form-item>
          <el-form-item label="密码">
            <el-input v-model="smtpPass" @input="validateSmtpPass"></el-input>
            <span v-if="smtpPassError">{{ smtpPassError }}</span>
          </el-form-item>
          <el-form-item>
            <el-checkbox v-model="useSSL" true-label="1" false-label="0"
              >启用SSL加密</el-checkbox
            >
          </el-form-item>
          <el-button @click="CheckDialogVisible = true">邮件检测</el-button>
          <el-button type="primary" @click="EditSMTPs()">保存</el-button>
        </el-form>
      </el-col>
      <el-col :span="12" :offset="0">
        <el-form>
          <el-form-item label="SMTP端口">
            <el-input v-model="smtpPort" @input="validateSmtpPort"></el-input>
            <span v-if="smtpPortError">{{ smtpPortError }}</span>
          </el-form-item>
          <el-form-item label="发件人邮箱账号">
            <el-input v-model="smtpSender" @input="validateEmail"></el-input>
            <span v-if="emailError">{{ emailError }}</span>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>

    <!-- 更新完成弹窗提示 -->
    <el-dialog
      title="温馨提示"
      :visible.sync="NoticeDialogVisible"
      width="30%"
      center
    >
      <span>此页面所配置内容已经保存至本地存储</span>
      <br />
      <span>如需更换此内容，则在此页面再次填写内容即可</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="NoticeDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="NoticeDialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 邮件检测弹窗 -->
    <el-dialog title="邮件检测" :visible.sync="CheckDialogVisible" width="30%">
      <el-form>
        <br />
        <el-form-item label="收信人">
          <el-input v-model="mailAddress"></el-input>
        </el-form-item>
        <el-form-item label="主题">
          <el-input v-model="title"></el-input>
        </el-form-item>
        <el-form-item label="正文">
          <el-input v-model="main" type="textarea" rows="3"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="CheckDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="CheckDialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { EditSMTP, GetSMTP } from "@/api/api";

export default {
  data() {
    return {
      NoticeDialogVisible: false,
      CheckDialogVisible: false,
      smtpHost: "",
      smtpPort: "",
      smtpUser: "",
      smtpPass: "",
      smtpSender: "",
      useSSL: "",
      smtpAuth: "",
      emailError: "",
      smtpHostError: "",
      smtpUserError: "",
      smtpPassError: "",
      smtpPortError: "",
    };
  },
  methods: {
    validateEmail() {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(this.smtpSender)) {
        this.emailError = "邮箱格式不正确";
      } else {
        this.emailError = "";
      }
    },
    validateSmtpHost() {
      if (!this.smtpHost) {
        this.smtpHostError = "SMTP服务器不能为空";
      } else {
        this.smtpHostError = "";
      }
    },
    validateSmtpUser() {
      if (!this.smtpUser) {
        this.smtpUserError = "用户名不能为空";
      } else {
        this.smtpUserError = "";
      }
    },
    validateSmtpPass() {
      if (!this.smtpPass) {
        this.smtpPassError = "密码不能为空";
      } else {
        this.smtpPassError = "";
      }
    },
    validateSmtpPort() {
      const portPattern = /^\d+$/;
      if (!portPattern.test(this.smtpPort)) {
        this.smtpPortError = "端口号必须是数字";
      } else {
        this.smtpPortError = "";
      }
    },
    GetSMTPs() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const token = localStorage.getItem("token");
      GetSMTP(token).then((res) => {
        if (res.data.code === 200) {
          this.smtpHost = res.data.data.smtpHost;
          this.smtpPort = res.data.data.smtpPort;
          this.smtpUser = res.data.data.smtpUser;
          this.smtpPass = res.data.data.smtpPass;
          this.smtpSender = res.data.data.smtpSender;
          this.useSSL = res.data.data.useSSL;
          this.smtpAuth = res.data.data.smtpAuth;
          loading.close();
        } else {
          this.$message.error(res.data.msg);
          loading.close();
        }
      });
    },
    EditSMTPs() {
      this.validateEmail();
      this.validateSmtpHost();
      this.validateSmtpUser();
      this.validateSmtpPass();
      this.validateSmtpPort();

      if (
        this.emailError ||
        this.smtpHostError ||
        this.smtpUserError ||
        this.smtpPassError ||
        this.smtpPortError
      ) {
        this.$message.error("请修正表单中的错误");
        return;
      }

      const token = localStorage.getItem("token");
      EditSMTP(
        token,
        this.smtpHost,
        this.smtpPort,
        this.smtpUser,
        this.smtpPass,
        this.smtpSender,
        this.useSSL,
        this.smtpAuth
      ).then((res) => {
        if (res.data.code === 200) {
          this.$message({
            message: "保存成功",
            type: "success",
          });
          this.NoticeDialogVisible = true;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
  },
  created() {
    this.GetSMTPs();
  },
};
</script>

<style scoped>
span {
  color: red;
}
</style>
