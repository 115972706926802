<template>
  <div>
    <div style="padding: 0 0 10px 10px">
      <el-button type="primary" @click="addDialogVisible = true">添加</el-button
      ><el-button type="danger">批量删除</el-button>
    </div>
    <!-- 数据展示表格 -->
    <el-table
      :data="tableData"
      v-loading="loading"
      border
      stripe
      style="width: 100%"
    >
      <el-table-column type="selection" width="50"> </el-table-column>
      <el-table-column prop="id" label="id"> </el-table-column>
      <el-table-column prop="username" label="用户名"> </el-table-column>
      <el-table-column prop="nickname" label="昵称"> </el-table-column>
      <el-table-column prop="email" label="电子邮箱"> </el-table-column>
      <el-table-column prop="mobile" label="手机号"> </el-table-column>
      <el-table-column prop="avatar" label="头像">
        <template slot-scope="scope">
          <img
            :src="scope.row.avatar"
            alt=""
            style="width: 50px; height: 50px"
          />
        </template>
      </el-table-column>
      <el-table-column prop="level" label="等级"></el-table-column>
      <el-table-column prop="gender" label="性别"></el-table-column>
      <el-table-column prop="money" label="余额"></el-table-column>
      <el-table-column prop="score" label="积分"></el-table-column>
      <el-table-column
        prop="successions"
        label="连续登录天数"
      ></el-table-column>
      <el-table-column
        prop="maxsuccessions"
        label="最大连续登录天数"
      ></el-table-column>
      <el-table-column prop="prevtime" label="上次登录时间">
        <template slot-scope="scope">
          {{ formatDate(scope.row.prevtime) }}
        </template>
      </el-table-column>
      <el-table-column prop="loginip" label="登录IP"></el-table-column>
      <el-table-column prop="joinip" label="注册IP"></el-table-column>
      <el-table-column prop="jointime" label="注册时间">
        <template slot-scope="scope">
          {{ formatDate(scope.row.jointime) }}
        </template>
      </el-table-column>
      <el-table-column prop="status" label="账号状态">
        <template slot-scope="scope">
          <!-- normal下显示 -->
          <div v-if="scope.row.status === 'normal'">
            <el-tag type="success">正常</el-tag>
          </div>
          <!-- 其他情况下显示 -->
          <div v-if="scope.row.status != 'normal'">
            <el-tag type="danger">异常</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <el-button type="primary" @click="OpenEditDialog(scope.row)"
            >编辑</el-button
          >
          <template>
            <el-popconfirm
              title="删除后数据不可恢复！确认继续删除吗"
              @confirm="DeleteMembers(scope.row.id)"
            >
              <el-button
                slot="reference"
                type="danger"
                style="margin-left: 10px"
                >删除</el-button
              >
            </el-popconfirm>
          </template>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <el-row :gutter="20" style="margin: 0 20px 0 20px">
      <el-col :span="12" :offset="0" style="line-height: 60px">
        <span>共 {{ total }} 页</span>
        <span style="margin-left: 20px">显示页数</span>
        <el-input-number
          v-model="list"
          controls-position="right"
          @change="GetRechargeLists"
          :min="1"
          :max="100"
          style="margin-left: 10px"
        ></el-input-number>
      </el-col>
      <el-col :span="12" :offset="0">
        <div
          style="
            display: flex;
            justify-content: right;
            align-items: right;
            margin-top: 20px;
          "
        >
          <el-button
            round
            style="padding: 10px"
            @click="changePage(currentPage - 1)"
            :disabled="currentPage === 1"
          >
            <i class="el-icon-arrow-left"></i>
          </el-button>
          <div v-for="page in total" :key="page" style="margin: 0 5px">
            <div
              @click="changePage(page)"
              :class="{ 'active-page': currentPage === page }"
              class="page-button"
            >
              {{ page }}
            </div>
          </div>
          <el-button
            round
            style="padding: 10px"
            @click="changePage(currentPage + 1)"
            :disabled="currentPage === total"
          >
            <i class="el-icon-arrow-right"></i>
          </el-button>
        </div>
      </el-col>
    </el-row>
    <!-- 添加弹窗 -->
    <el-dialog title="添加会员" :visible.sync="addDialogVisible" width="70%">
      <!-- <el-row :gutter="20">
        <el-col :span="8" :offset="0">
          <el-form>
            <el-form-item label="用户名">
              <el-input v-model="addData.username"></el-input>
            </el-form-item>
            <el-form-item label="密码">
              <el-input v-model="addData.password"></el-input>
            </el-form-item>
            <el-form-item label="昵称">
              <el-input v-model="addData.nickname"></el-input>
            </el-form-item>
            <el-form-item label="电子邮箱">
              <el-input v-model="addData.email"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="8" :offset="0">
          <el-form>
            <el-form-item label="手机号">
              <el-input v-model="addData.phone"></el-input>
            </el-form-item>
            <el-form-item label="头像">
              <el-input v-model="addData.avatar"></el-input>
            </el-form-item>
            <el-form-item label="等级">
              <el-input v-model="addData.level"></el-input>
            </el-form-item>
            <el-form-item label="性别">
              <el-input v-model="addData.gender"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="8" :offset="0">
          <el-form>
            <el-form-item label="余额">
              <el-input v-model="addData.money"></el-input>
            </el-form-item>
            <el-form-item label="积分">
              <el-input v-model="addData.scope"></el-input>
            </el-form-item>
            <el-form-item label="账号状态">
              <el-input v-model="addData.status"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row> -->
      <el-form>
        <el-form-item label="请输入手机号">
          <el-input v-model="mobile"></el-input>
        </el-form-item>
        <el-form-item label="请输入密码">
          <el-input v-model="password"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="AddMembers()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑弹窗 -->
    <el-dialog title="编辑会员" :visible.sync="editDialogVisible" width="70%">
      <el-row :gutter="20">
        <el-col :span="8" :offset="0">
          <el-form>
            <el-form-item label="用户名">
              <el-input v-model="editData.username"></el-input>
            </el-form-item>
            <el-form-item label="密码">
              <el-input v-model="editData.password"></el-input>
            </el-form-item>
            <el-form-item label="昵称">
              <el-input v-model="editData.nickname"></el-input>
            </el-form-item>
            <el-form-item label="电子邮箱">
              <el-input v-model="editData.email"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="8" :offset="0">
          <el-form>
            <el-form-item label="手机号">
              <el-input v-model="editData.mobile"></el-input>
            </el-form-item>
            <el-form-item label="头像">
              <el-input v-model="editData.avatar"></el-input>
            </el-form-item>
            <el-form-item label="等级">
              <el-input v-model="editData.level"></el-input>
            </el-form-item>
            <el-form-item label="性别">
              <el-input v-model="editData.gender"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="8" :offset="0">
          <el-form>
            <el-form-item label="余额">
              <el-input v-model="editData.money"></el-input>
            </el-form-item>
            <el-form-item label="积分">
              <el-input v-model="editData.scope"></el-input>
            </el-form-item>
            <el-form-item label="账号状态">
              <el-input v-model="editData.status"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="EditMembers()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import md5 from "blueimp-md5";
import { AddMember, GetMembersList, EditMember, DeleteMember } from "@/api/api";
import moment from "moment";

export default {
  data() {
    return {
      page: 1,
      list: 5,
      total: 0, // 总页数
      currentPage: 1,
      tableData: [],
      loading: false,
      addDialogVisible: false,
      editDialogVisible: false,
      addData: {},
      editData: {},
      editId: "",
      username: "",
      password: "",
      mobile: "",
      salt: "",
    };
  },
  methods: {
    changePage(page) {
      if (page < 1 || page > this.total) return;
      this.currentPage = page;
      this.page = page;
      this.GetMembersLists();
    },
    //打开编辑弹窗
    OpenEditDialog(row) {
      this.editData = row;
      this.editId = row.id;
      this.editData.password = "";
      this.editDialogVisible = true;
    },
    //时间格式化
    formatDate(date) {
      return moment.unix(date).format("YYYY-MM-DD HH:mm:ss");
    },
    GetMembersLists() {
      const token = localStorage.getItem("token");
      GetMembersList(token, this.page, this.list).then((res) => {
        if (res.data.code === 200) {
          this.tableData = res.data.data.data;
          this.total = res.data.data.total;
        } else {
          this.$message.error("获取数据失败，请刷新页面重试");
        }
      });
    },
    AddMembers() {
      this.username = this.mobile;
      const token = localStorage.getItem("token");
      AddMember(token, this.username, this.password).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("添加成功");
          this.addDialogVisible = false;
          this.GetMembersLists();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    // 比较并提取修改的字段
    getModifiedFields(original, current) {
      let modified = {};
      for (let key in current) {
        if (current[key] !== original[key]) {
          modified[key] = current[key];
        }
      }
      return modified;
    },

    // 哈希加密方法
    hashPassword(password, salt) {
      return md5(md5(password) + salt);
    },

    // 编辑成员的方法
    EditMembers() {
      const token = localStorage.getItem("token");
      let modifiedData = this.getModifiedFields(
        this.originalEditData,
        this.editData
      );

      // 对密码字段进行加密，并分开上传加密后的密码和盐值
      if (this.editData.password) {
        const hashedPassword = this.hashPassword(
          this.editData.password,
          this.editData.salt
        );
        modifiedData.password = hashedPassword;
        modifiedData.salt = this.editData.salt;
      }

      EditMember(token, this.editId, modifiedData).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("修改成功");
          this.editDialogVisible = false;
          this.GetMembersLists();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    DeleteMembers(id) {
      DeleteMember(id).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("删除成功");
          this.GetMembersLists();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
  },
  created() {
    this.GetMembersLists();
  },
  // 在打开编辑弹窗时记录初始数据
  mounted() {
    this.$watch("editDialogVisible", (newVal) => {
      if (newVal) {
        this.originalEditData = { ...this.editData }; // 深拷贝初始数据
      }
    });
  },
};
</script>
