<template>
  <div>
    <div>
      <el-button
        type="success"
        @click="dialogVisible = true"
        style="margin: 0 0 10px 10px"
        >新增全局配置</el-button
      >
    </div>
    <el-table
      v-loading="loading"
      :data="tableData"
      border
      stripe
      style="width: 100%"
    >
      <el-table-column type="selection"> </el-table-column>
      <el-table-column prop="id" label="id"> </el-table-column>
      <el-table-column prop="name" label="配置名称"> </el-table-column>
      <el-table-column prop="value" label="配置值"> </el-table-column>
      <el-table-column prop="title" label="标题"> </el-table-column>
      <el-table-column prop="tip" label="提示"> </el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <el-button type="primary" @click="openEditDialog(scope.row)"
            >修改</el-button
          >
          <el-popconfirm
            title="删除后不可恢复！确定删除吗？"
            @confirm="DelConfigs(scope.row.id)"
          >
            <el-button>删除</el-button>
            <el-button slot="reference" type="danger" style="margin-left: 10px"
              >删除</el-button
            >
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <!-- 新增全局配置 -->
    <el-dialog title="新增全局配置" :visible.sync="dialogVisible" width="30%">
      <el-form label-width="50">
        <el-form-item label="请输入配置名称">
          <el-input v-model="addName"></el-input>
        </el-form-item>
        <el-form-item label="请输入配置值">
          <el-input v-model="addValue"></el-input>
        </el-form-item>
        <el-form-item label="请输入配置标题">
          <el-input v-model="addTitle"></el-input>
        </el-form-item>
        <el-form-item label="请输入配置提示">
          <el-input v-model="addTip"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="AddConfigs()">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 分页器 -->
    <el-row :gutter="20" style="margin: 0 20px 0 20px">
      <el-col :span="12" :offset="0" style="line-height: 60px">
        <span>共 {{ total }} 页</span>
        <span style="margin-left: 20px">显示页数</span>
        <el-input-number
          v-model="list"
          controls-position="right"
          @change="GetRechargeLists"
          :min="1"
          :max="100"
          style="margin-left: 10px"
        ></el-input-number>
      </el-col>
      <el-col :span="12" :offset="0">
        <div
          style="
            display: flex;
            justify-content: right;
            align-items: right;
            margin-top: 20px;
          "
        >
          <el-button
            round
            style="padding: 10px"
            @click="changePage(currentPage - 1)"
            :disabled="currentPage === 1"
          >
            <i class="el-icon-arrow-left"></i>
          </el-button>
          <div v-for="page in total" :key="page" style="margin: 0 5px">
            <div
              @click="changePage(page)"
              :class="{ 'active-page': currentPage === page }"
              class="page-button"
            >
              {{ page }}
            </div>
          </div>
          <el-button
            round
            style="padding: 10px"
            @click="changePage(currentPage + 1)"
            :disabled="currentPage === total"
          >
            <i class="el-icon-arrow-right"></i>
          </el-button>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import moment from "moment";
import { GetConfigList, AddConfig, EditConfig, DelConfig } from "@/api/api";

export default {
  data() {
    return {
      tableData: [],
      loading: false,
      page: 1,
      list: 5,
      total: 0, // 总页数
      currentPage: 1,
      addName: "",
      addValue: "",
      addTitle: "",
      addTip: "",
      dialogVisible: false,
      EditDialogvisible: false,
      editId: "",
      editName: "",
      editValue: "",
      editTitle: "",
      editTip: "",
    };
  },
  methods: {
    openEditDialog(row) {
      this.editId = row.id;
      this.editName = row.name;
      this.editValue = row.value;
      this.editTitle = row.title;
      this.editTip = row.tip;
      this.EditDialogvisible = true;
    },
    changePage(page) {
      if (page < 1 || page > this.total) return;
      this.currentPage = page;
      this.page = page;
      this.GetConfigLists();
    },
    // 时间格式化
    formatDate(date) {
      return moment.unix(date).format("YYYY-MM-DD HH:mm:ss");
    },
    GetConfigLists() {
      this.loading = true;
      const token = localStorage.getItem("token");
      GetConfigList(token, this.page, this.list).then((res) => {
        if (res.data.code === 200) {
          this.tableData = res.data.data.data;
          this.total = res.data.data.total;
          this.loading = false;
        } else {
          this.$message.error(res.data.msg);
          this.loading = false;
        }
      });
    },
    AddConfigs() {
      const token = localStorage.getItem("token");
      AddConfig(
        token,
        this.addName,
        this.addValue,
        this.addTitle,
        this.addTip
      ).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("添加成功");
          this.dialogVisible = false;
          this.GetConfigLists();
        } else {
          this.$message.error(res.data.msg);
          this.dialogVisible = false;
        }
      });
    },
    EditConfigs() {
      const token = localStorage.getItem("token");
      EditConfig(
        token,
        this.editId,
        this.editName,
        this.editValue,
        this.editTitle,
        this.editTip
      ).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("编辑成功");
          this.EditDialogvisible = false;
          this.GetConfigLists();
        } else {
          this.$message.error(res.data.msg);
          this.EditDialogvisible = false;
        }
      });
    },
    DelConfigs(id) {
      const token = localStorage.getItem("token");
      DelConfig(token, id).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("删除成功");
          this.GetConfigLists();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
  },
  created() {
    this.GetConfigLists();
  },
};
</script>
