<template>
  <div>
    <div>
      <el-button
        type="success"
        @click="dialogVisible = true"
        style="margin: 0 0 10px 10px"
        >添加价格计算配置</el-button
      >
    </div>
    <!-- 数据展示表格 -->
    <el-table
      :data="tableData"
      v-loading="loading"
      border
      stripe
      style="width: 100%"
    >
      <el-table-column type="selection"> </el-table-column>
      <el-table-column prop="id" label="id"> </el-table-column>
      <el-table-column prop="name" label="配置名称"> </el-table-column>
      <el-table-column prop="value" label="配置值"> </el-table-column>
      <el-table-column prop="tip" label="提示"> </el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <el-button type="primary" @click="OpenRowDialog(scope.row)"
            >编辑</el-button
          >
          <el-popconfirm
            title="数据删除后无法恢复！确认删除吗？"
            @confirm="DelCalcConfigs(scope.row.id)"
          >
            <el-button
              slot="reference"
              type="danger"
              style="margin-left: 10px"
              @click="DelCalcConfig(scope.row.id)"
              >删除</el-button
            >
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <!-- 添加弹窗 -->
    <el-dialog
      title="添加价格计算配置"
      :visible.sync="dialogVisible"
      width="30%"
    >
      <el-form label-width="50">
        <el-form-item label="请输入配置名称">
          <el-input v-model="addName"></el-input>
        </el-form-item>
        <el-form-item label="请输入配置值">
          <el-input v-model="addValue"></el-input>
        </el-form-item>
        <el-form-item label="请输入配置提示">
          <el-input v-model="addTip"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="AddCalcConfigs()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑弹窗 -->
    <el-dialog
      title="编辑价格计算配置"
      :visible.sync="EditDialogVisible"
      width="30%"
    >
      <el-form label-width="50">
        <el-form-item label="请输入配置名称">
          <el-input v-model="editName"></el-input>
        </el-form-item>
        <el-form-item label="请输入配置值">
          <el-input v-model="editValue"></el-input>
        </el-form-item>
        <el-form-item label="请输入配置提示">
          <el-input v-model="editTip"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="EditDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="EditCalcConfigs()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  CalcConfigList,
  AddCalcConfig,
  EditCalcConfig,
  DelCalcConfig,
} from "@/api/api";

export default {
  data() {
    return {
      tableData: [],
      loading: false,
      dialogVisible: false,
      addName: "",
      addValue: "",
      addTip: "",
      EditDialogVisible: false,
      editName: "",
      editValue: "",
      editTip: "",
    };
  },
  methods: {
    CalcConfigLists() {
      this.loading = true;
      const token = localStorage.getItem("token");
      CalcConfigList(token).then((res) => {
        if (res.data.code === 200) {
          this.tableData = res.data.data;
          this.loading = false;
        } else {
          this.$message.error(res.data.msg);
          this.loading = false;
        }
      });
    },
    AddCalcConfigs() {
      const token = localStorage.getItem("token");
      AddCalcConfig(token, this.addName, this.addValue, this.addTip).then(
        (res) => {
          if (res.data.code === 200) {
            this.$message.success("添加成功");
            this.dialogVisible = false;
            this.CalcConfigLists();
          } else {
            this.$message.error(res.data.msg);
            this.dialogVisible = false;
          }
        }
      );
    },
    EditCalcConfigs() {
      const token = localStorage.getItem("token");
      EditCalcConfig(
        token,
        this.editId,
        this.editName,
        this.editValue,
        this.editTip
      ).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("修改成功");
          this.EditDialogVisible = false;
          this.CalcConfigLists();
        } else {
          this.$message.error(res.data.msg);
          this.EditDialogVisible = false;
        }
      });
    },
    OpenRowDialog(row) {
      this.EditDialogVisible = true;
      this.editId = row.id;
      this.editName = row.name;
      this.editValue = row.value;
      this.editTitle = row.title;
      this.editTip = row.tip;
    },
    DelCalcConfigs(id) {
      const token = localStorage.getItem("token");
      DelCalcConfig(token, id).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("删除成功");
          this.CalcConfigLists();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
  },
  created() {
    this.CalcConfigLists();
  },
};
</script>
