<template>
  <div>
    <!-- 数据展示表格 -->
    <el-table
      :data="tableData"
      v-loading="loading"
      border
      stripe
      style="width: 100%"
    >
      <el-table-column type="selection"> </el-table-column>
      <el-table-column prop="id" label="id"> </el-table-column>
      <el-table-column prop="invoice_head" label="发票抬头"> </el-table-column>
      <el-table-column prop="invoice_code" label="用户ID"> </el-table-column>
      <el-table-column prop="data_flag" label="数据有效标记"> </el-table-column>
      <el-table-column prop="createtime" label="创建时间">
        <template slot-scope="scope">
          {{ formatDate(scope.row.createtime) }}
        </template>
      </el-table-column>
      <el-table-column prop="invoice_type" label="发票类型"> </el-table-column>
      <el-table-column prop="invoice_addr" label="专票注册地址">
      </el-table-column>
      <el-table-column prop="invoice_phone_number" label="专票注册电话">
      </el-table-column>
      <el-table-column prop="invoice_bank_name" label="专票开户银行">
      </el-table-column>
      <el-table-column prop="invoice_bank_no" label="专票银行卡号">
      </el-table-column>
      <el-table-column prop="is_send_email" label="是否发送邮箱">
      </el-table-column>
      <el-table-column prop="email" label="邮箱账号"> </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <el-row :gutter="20" style="margin: 0 20px 0 20px">
      <el-col :span="12" :offset="0" style="line-height: 60px">
        <span>共 {{ total }} 页</span>
        <span style="margin-left: 20px">显示页数</span>
        <el-input-number
          v-model="list"
          controls-position="right"
          @change="GetRechargeLists"
          :min="1"
          :max="100"
          style="margin-left: 10px"
        ></el-input-number>
      </el-col>
      <el-col :span="12" :offset="0">
        <div
          style="
            display: flex;
            justify-content: right;
            align-items: right;
            margin-top: 20px;
          "
        >
          <el-button
            round
            style="padding: 10px"
            @click="changePage(currentPage - 1)"
            :disabled="currentPage === 1"
          >
            <i class="el-icon-arrow-left"></i>
          </el-button>
          <div v-for="page in total" :key="page" style="margin: 0 5px">
            <div
              @click="changePage(page)"
              :class="{ 'active-page': currentPage === page }"
              class="page-button"
            >
              {{ page }}
            </div>
          </div>
          <el-button
            round
            style="padding: 10px"
            @click="changePage(currentPage + 1)"
            :disabled="currentPage === total"
          >
            <i class="el-icon-arrow-right"></i>
          </el-button>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { getMemberInvoicesList } from "@/api/api";
import moment from "moment";

export default {
  data() {
    return {
      tableData: [],
      loading: false,
      page: 1,
      list: 5,
      total: 0, // 总页数
      currentPage: 1,
    };
  },
  methods: {
    changePage(page) {
      if (page < 1 || page > this.total) return;
      this.currentPage = page;
      this.page = page;
      this.getMemberInvoicesLists();
    },
    //格式化Unix时间戳
    formatDate(date) {
      return moment.unix(date).format("YYYY-MM-DD HH:mm:ss");
    },
    getMemberInvoicesLists() {
      this.loading = true;
      const token = localStorage.getItem("token");
      getMemberInvoicesList(token, this.page, this.list).then((res) => {
        if (res.data.code === 200) {
          this.tableData = res.data.data.data;
          this.total = res.data.data.total;
          this.loading = false;
        } else {
          this.$message.error(res.data.msg);
          this.loading = false;
        }
      });
    },
  },
  created() {
    this.getMemberInvoicesLists();
  },
};
</script>
