<template>
  <div id="app" style="padding: 40px">
    <p>邮件标题{{ subject }}</p>
    <p>收件人{{ sendTo }}</p>
    <p>邮件正文{{ body }}</p>
    <el-form label-width="80px">
      <el-form-item label="邮件标题">
        <el-input v-model="subject"></el-input>
      </el-form-item>
      <el-form-item label="收件人">
        <el-input v-model="sendTo" style="width: 92%"></el-input>
        <el-button type="primary" style="margin-left: 10px">导入</el-button>
      </el-form-item>
      <el-form-item label="邮件正文">
        <el-input v-model="body" type="textarea" :rows="10"></el-input>
        <span @click="dialogVisible = true" style="color: #303133"
          >+添加附件</span
        >
      </el-form-item>
      <el-input-item>
        <el-button type="primary" style="float: right" @click="SendMails()"
          >发送</el-button
        >
      </el-input-item>
    </el-form>
    <!-- 添加附件弹窗 -->
    <el-dialog title="添加附件" :visible.sync="dialogVisible" width="30%">
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { SendMail } from "@/api/api";
export default {
  data() {
    return {
      subject: "",
      sendTo: "",
      body: "",
      altBody: "",
      dialogVisible: false,
    };
  },
  methods: {
    SendMails() {
      if (!this.subject || !this.sendTo || !this.body) {
        this.$message.error("请填写完整信息");
      } else {
        const token = localStorage.getItem("token");
        SendMail(token, this.sendTo, this.subject, this.body).then((res) => {
          if (res.data.code === 200) {
            this.$message.success("邮件发送成功");
          } else {
            this.$message.error(res.data.msg);
          }
        });
      }
    },
  },
  created() {},
};
</script>

<style scoped>
/* 可以在这里添加组件样式 */
</style>
