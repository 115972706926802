<template>
  <div>
    <div>
      <el-button
        type="success"
        @click="dialogVisible = true"
        style="margin: 0 0 10px 10px"
        >添加折扣</el-button
      >
    </div>
    <!-- 数据展示表格 -->
    <el-table
      v-loading="loading"
      :data="tableData"
      border
      stripe
      style="width: 100%"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column prop="id" label="id"> </el-table-column>
      <el-table-column prop="name" label="会员名称"> </el-table-column>
      <el-table-column prop="level" label="等级"> </el-table-column>
      <el-table-column prop="exp" label="经验值"> </el-table-column>
      <el-table-column prop="discount" label="会员折扣"> </el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <el-button type="primary" @click="OpenEditDialog(scope.row)"
            >修改</el-button
          >
          <template>
            <el-popconfirm
              title="数据删除后无法恢复！确定删除吗？"
              @confirm="DeleteUserLevels(scope.row.id)"
            >
              <el-button
                slot="reference"
                type="danger"
                style="margin-left: 10px"
                >删除</el-button
              >
            </el-popconfirm>
          </template>
        </template>
      </el-table-column>
    </el-table>
    <!-- 添加数据弹窗 -->
    <el-dialog title="添加折扣" :visible.sync="dialogVisible" width="30%">
      <el-form label-width="40px">
        <el-form-item label="等级">
          <el-input v-model="addLevel"></el-input>
        </el-form-item>
        <el-form-item label="名称">
          <el-input v-model="addName"></el-input>
        </el-form-item>
        <el-form-item label="经验">
          <el-input v-model="addExp"></el-input>
        </el-form-item>
        <el-form-item label="折扣">
          <el-input v-model="addDiscount"></el-input>
          <span>关于会员折扣：范围为0.01~1&nbsp;例如：0.95表示95折</span>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="AddUserLevels()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改数据弹窗 -->
    <el-dialog title="修改折扣" :visible.sync="editDialogVisible" width="30%">
      <el-form label-width="40px">
        <el-form-item label="等级">
          <el-input v-model="editLevel"></el-input>
        </el-form-item>
        <el-form-item label="名称">
          <el-input v-model="editName"></el-input>
        </el-form-item>
        <el-form-item label="经验">
          <el-input v-model="editExp"></el-input>
        </el-form-item>
        <el-form-item label="折扣">
          <el-input v-model="editDiscount"></el-input>
          <span>关于会员折扣：范围为0.01~1&nbsp;例如：0.95表示95折</span>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="EditUserLevels()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  AddUserLevel,
  GetUserLevel,
  DeleteUserLevel,
  EditUserLevel,
} from "@/api/api";

export default {
  data() {
    return {
      tableData: [],
      loading: false,
      dialogVisible: false,
      editDialogVisible: false,
      addLevel: "",
      addName: "",
      addExp: "",
      addDiscount: "",
      editId: "",
      editLevel: "",
      editName: "",
      editExp: "",
      editDiscount: "",
    };
  },
  methods: {
    GetUserLevels() {
      this.loading = true;
      const token = localStorage.getItem("token");
      GetUserLevel(token).then((res) => {
        if (res.data.code === 200) {
          this.tableData = res.data.data;
          this.loading = false;
        } else {
          this.$message.error(res.data.msg);
          this.loading = false;
        }
      });
    },
    AddUserLevels() {
      const token = localStorage.getItem("token");
      AddUserLevel(
        token,
        this.addLevel,
        this.addName,
        this.addExp,
        this.addDiscount
      ).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("添加成功");
          this.dialogVisible = false;
          this.GetUserLevels();
        } else {
          this.$message.error(res.data.msg);
          this.dialogVisible = false;
        }
      });
    },
    DeleteUserLevels(id) {
      const token = localStorage.getItem("token");
      DeleteUserLevel(token, id).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("删除成功");
          this.GetUserLevels();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    EditUserLevels() {
      const token = localStorage.getItem("token");
      EditUserLevel(
        token,
        this.editId,
        this.editLevel,
        this.editName,
        this.editExp,
        this.editDiscount
      ).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("修改成功");
          this.editDialogVisible = false;
          this.GetUserLevels();
        } else {
          this.$message.error(res.data.msg);
          this.editDialogVisible = false;
        }
      });
    },
    OpenEditDialog(row) {
      this.editId = row.id;
      this.editLevel = row.level;
      this.editName = row.name;
      this.editExp = row.exp;
      this.editDiscount = row.discount;
      this.editDialogVisible = true;
    },
  },
  created() {
    this.GetUserLevels();
  },
};
</script>
